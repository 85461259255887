import {makeStyles} from '@material-ui/core/styles';
import {Select} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
        maxWidth: 600,
        minWidth: 140,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 450,
        },
    },
};
const UserAlertsTable = ({alerts, boxAl, handleBoxClickAl, handleBoxAl}) => {
    const classes = useStyles();
    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel>Alerts</InputLabel>
                <Select
                    multiple
                    value={boxAl.filter(Boolean)}
                    input={<Input />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {alerts.map((alert, index) => (
                        <MenuItem key={alert.type} value={alert.type}>
                            <Checkbox
                                checked={boxAl[index]}
                                value={alert.type}
                                onClick={(e) => {
                                    handleBoxClickAl(alert, index);
                                    handleBoxAl(e.target.checked, index);
                                }}
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                            <ListItemText primary={alert.type} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

UserAlertsTable.propTypes = {
    alerts: PropTypes.object.isRequired,
    boxAl: PropTypes.object.isRequired,
    handleBoxClickAl: PropTypes.object.isRequired,
    handleBoxAl: PropTypes.object.isRequired,
};

export default UserAlertsTable;
