import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import jwtDecode from 'jwt-decode';
import axios from '../../helpers/axios';
import {setDevType, setDut, setManufacturer, setMode, setSpecVer, setSucces} from '../../redux/actions/searchAction';
import DateRange from '../../components/dateRange';
import './styles/styles.scss';
import PropTypes from 'prop-types';
import log from '../../logging';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    headBackground: {
        backgroundColor: '#E9ECF1',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    tableCell: {cursor: 'pointer'},
    tableCell2: {
        textAlign: 'center',
    },
    tableCellFailed: {
        backgroundColor: '#F9CCCC',
        cursor: 'pointer',
    },
}));

const TestRunsTableContent = ({posts}) => {
    log.debug('inside entrance of TRTableContent functional component');
    log.debug(`Received props: \\n posts:${posts}`);

    const token = localStorage.getItem('accessToken');// token previously
    const decodedToken = jwtDecode(token);

    // Extract the custom Authorities attribute from the token
    const decodedMnf = decodedToken['custom:manufacturer'];


// Convert the string into an array by splitting it (assuming it's comma-separated)

// Check if the user is part of the 'sphinxQA' group and store the result
    const history = useHistory();
    const classes = useStyles();

    const deviceTypeStore = useSelector((state) => state.search.device_typeEP);
    const specVersionStore = useSelector((state) => state.search.specversionEP);
    const manufacturerStore = useSelector((state) => state.search.manufacturerEP);
    const dutStore = useSelector((state) => state.search.dutEP);
    const successStore = useSelector((state) => state.search.successEP);
    const modeStore = useSelector((state) => state.search.modeEP);

    const [id, setId] = useState('');

    const [dutST, setDutST] = useState(dutStore);
    const [specVersion, setSpecVersion] = useState(specVersionStore);

    const [manufacturerST, setManufacturerST] = useState(manufacturerStore);
    const [isManufacturer, setIsManufacturer] = useState(false);
    useEffect(() => {
    if (!!decodedToken['custom:manufacturer']) {
        log.debug('inside 2nd if of TRTContent functional component');
        setIsManufacturer(true);
        setManufacturerST(decodedToken['custom:manufacturer']);
    }
    }, []);
    const [deviceType, setDeviceType] = useState(deviceTypeStore);
    const [modeST, setModeST] = useState(modeStore);
    const [successST, setSuccessST] = useState(successStore);

    const dispatch = useDispatch();

    useEffect(() => {
        log.debug('inside TRTContent useEffect triggered by changes in any of the redux store values');

        setDutST(dutStore);
        setSpecVersion(specVersionStore);
        setManufacturerST(manufacturerStore);
        setDeviceType(deviceTypeStore);
        setModeST(modeStore);
        setSuccessST(successStore);
    }, [dutStore, specVersionStore, manufacturerStore, deviceTypeStore, modeStore, successStore]);

    useEffect(() => {
        log.debug('inside TRTContent useEffect for initial mount');

        setDutST(dutStore);
        setSpecVersion(specVersionStore);
        setManufacturerST(manufacturerStore);
        setDeviceType(deviceTypeStore);
        setModeST(modeStore);
        setSuccessST(successStore);
    }, []);

    useEffect(() => {
        log.debug('inside TRTContent useEffect for updation of filterState');

        dispatch(setDut(dutST));
        dispatch(setSpecVer(specVersion));
        dispatch(setManufacturer(manufacturerST));
        dispatch(setDevType(deviceType));
        dispatch(setSucces(successST));
        dispatch(setMode(modeST));
    }, [dispatch, deviceType, specVersion, manufacturerST, dutST, successST, modeST]);

    const [manufacturerArr, setManufacturerArr] = useState([]);
    useEffect(() => {
        axios
            .get('/FactoryREST/rest/v1/testrun/manufacturers')
            .then((res) =>
                setManufacturerArr(
                    res.data.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    }),
                ),
            )
            .catch((err) => log.debug('error: ', err));
    }, []);

    const [deviceTypeArr, setDeviceTypeArr] = useState([]);
    useEffect(() => {
        axios
            .get('/FactoryREST/rest/v1/testspec/devices-type')
            .then((res) =>
                setDeviceTypeArr(
                    res.data.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    }),
                ),
            )
            .catch((err) => log.debug('error: ', err));
    }, []);

    const handleBrowseId = (e) => {
        if (e.key === 'Enter') {
            history.push(`/testruns/byid/${id}`);
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table id="testrun-table" className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow className={classes.headBackground} id="table_row">
                        <TableCell className={classes.tableCell}>Id</TableCell>
                        <TableCell className={classes.tableCell}>Device type</TableCell>
                        <TableCell className={classes.tableCell}>Spec ver.</TableCell>
                        <TableCell className={classes.tableCell}>Manufacturer</TableCell>
                        <TableCell className={classes.tableCell2}>Test time</TableCell>
                        <TableCell className={classes.tableCell}>Device Id</TableCell>
                        <TableCell className={classes.tableCell}>Success</TableCell>
                        <TableCell className={classes.tableCell}>Mode</TableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow className="tableRow" id="table_filters">
                        <TableCell className="tableRowCell">
                            <TextField
                                className="tableRowCellSV"
                                placeholder="Browse"
                                size="small"
                                value={id}
                                onKeyDown={handleBrowseId}
                                onChange={(e) => setId(e.target.value)}
                            />
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <FormControl size="small" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={deviceType || ''}
                                    onChange={(e) => setDeviceType(e.target.value)}
                                    size="small"
                                    name="all"
                                    displayEmpty
                                >
                                    {deviceTypeArr.length !== 0
                                        ? deviceTypeArr.map((item) => {
                                              return (
                                                  <MenuItem value={`${item}`} key={`${item}`}>
                                                      {item === '' ? 'All' : item}
                                                  </MenuItem>
                                              );
                                          })
                                        : ''}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <TextField
                                className="tableRowCellSV"
                                placeholder="Search"
                                size="small"
                                value={specVersion}
                                onChange={(e) => setSpecVersion(e.target.value)}
                            />
                        </TableCell>

                        <TableCell className="tableRowCell">
                            <FormControl size="small" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={manufacturerST || ''}
                                    onChange={(e) => {
                                        if (!isManufacturer) {
                                            setManufacturerST(e.target.value);
                                        } else {
                                            setManufacturerST(decodedMnf);
                                    }
}}
                                    size="small"
                                    name="all"
                                    displayEmpty
                                >
                                    {manufacturerArr.length !== 0 ? (
                                        manufacturerArr.map((item) => {
                                            return (
                                                <MenuItem value={`${item}`} key={`${item}`}>
                                                    {item === '' ? 'All' : item}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem size="small" value={''}>
                                            All
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <DateRange />
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <TextField
                                className="tableRowCellSV"
                                placeholder="Search"
                                size="small"
                                value={dutST}
                                onChange={(e) => setDutST(e.target.value)}
                            />
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <FormControl size="small" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={successST}
                                    onChange={(e) => setSuccessST(e.target.value)}
                                    size="small"
                                    displayEmpty
                                >
                                    <MenuItem value={''}>All</MenuItem>
                                    <MenuItem value={'true'}>True</MenuItem>
                                    <MenuItem value={'false'}>False</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <FormControl size="small" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={modeST}
                                    onChange={(e) => setModeST(e.target.value)}
                                    size="small"
                                    displayEmpty
                                >
                                    <MenuItem value={''}>All</MenuItem>
                                    <MenuItem value={'production'}>Production</MenuItem>
                                    <MenuItem value={'development'}>Development</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {posts.map((post) => {
                        return (
                            <TableRow
                                key={post.id}
                                onClick={() => history.push(`/testruns/byid/${post.id}`)}
                                className={post.measurements.test_success ? classes.tableCell : classes.tableCellFailed}
                            >
                                <TableCell className={classes.tableCell}>{post.id}</TableCell>
                                <TableCell className={classes.tableCell}>{post.device_under_test.type}</TableCell>
                                <TableCell className={classes.tableCell}>{post.tester.test_spec_version}</TableCell>
                                <TableCell className={classes.tableCell}>
                                    {post.device_under_test.manufacturer_id}
                                </TableCell>
                                <TableCell className={classes.tableCell2}>
                                    {post.test_start_time.substr(0, 19).replace('T', ' ')}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{post.device_under_test.dut_id}</TableCell>
                                <TableCell className={classes.tableCell}>
                                    {post.measurements.test_success !== null ? `${post.measurements.test_success}` : ''}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{post.test_mode}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TestRunsTableContent.propTypes = {
    posts: PropTypes.array.isRequired,
};

export default TestRunsTableContent;
