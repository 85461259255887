import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {setTester} from '../../../redux/actions/reportAction';
import axios from '../../../helpers/axios';

export const Tester = () => {
    const getTesters = async () => {
        const res = await axios.get(`/FactoryREST/rest/v1/report/testers`);
        return setTestersArr(
            res.data.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            }),
        );
    };

    const [testerst, setTesterst] = useState('');
    const [testersArr, setTestersArr] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getTesters();
    }, []);
    useEffect(() => {
        dispatch(setTester(testerst));
    }, [dispatch, testerst]);

    return (
        <FormControl size="small" className="report__input">
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                size="small"
                name="all"
                displayEmpty
                value={testerst}
                onChange={(e) => setTesterst(e.target.value)}
            >
                <MenuItem size="small" value={''}>
                    All
                </MenuItem>
                {testersArr.length !== 0
                    ? testersArr.map((item, i) => {
                          return (
                              <MenuItem key={i} value={`${item}`}>
                                  {item}
                              </MenuItem>
                          );
                      })
                    : ''}
            </Select>
        </FormControl>
    );
};
