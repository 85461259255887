import {combineReducers} from 'redux';
import authReducer from './reducers/authReducer';
import searchReducer from './reducers/searchReducer';
import createTSReducer from './reducers/createTSReducer';
import editTSReducer from './reducers/editTSReducer';
import newUserReducer from './reducers/newUserReducer';
import tabReducer from './reducers/tabReducer';
import reportReducer from './reducers/reportReducer';

export default combineReducers({
    auth: authReducer,
    search: searchReducer,
    createTS: createTSReducer,
    editTS: editTSReducer,
    newUser: newUserReducer,
    tab: tabReducer,
    report: reportReducer,
});
