import TopTable from '../../components/toptable';
import AlertAdministrationTableContent from './AlertAdministrationTableContent';

function AlertAdministrationTable() {
    return (
        <div className="table__container">
            <TopTable>
                <TopTable.Title>Alerts</TopTable.Title>
                <TopTable.Body />
            </TopTable>
            <AlertAdministrationTableContent />
        </div>
    );
}

export default AlertAdministrationTable;
