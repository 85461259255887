import TextField from '@material-ui/core/TextField';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {setDeviceId} from '../../../redux/actions/reportAction';

export const DeviceId = () => {
    const [deviceidst, setDeviceidst] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDeviceId(deviceidst));
    }, [dispatch, deviceidst]);

    return (
        <TextField
            className="tableRowCellSV"
            id="outlined-basic"
            placeholder="All"
            size="small"
            value={deviceidst}
            onChange={(e) => setDeviceidst(e.target.value)}
        />
    );
};
