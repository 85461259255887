import {Redirect, Route, Switch} from 'react-router-dom';
import AlertAdministration from './pages/alerts';
import Alert from './pages/alerts/Alert';
import ChangePassword from './pages/changepassword';
import Info from './pages/info';
import HomePage from './pages/home';
import TestRuns from './pages/testruns';
import TestRunsById from './pages/testruns/TestRunsById';
import CreateTestSpec from './pages/testspecs/CreateTestSpecs';
import FullSample from './pages/testspecs/samples/FullSample';
import MinimumSample from './pages/testspecs/samples/MinimumSample';
import TestSpecById from './pages/testspecs/TestSpecById';
import TestSpecs from './pages/testspecs';
import NewUser from './pages/users/NewUser';
import Users from './pages/users';
import UserById from './pages/users/UserById';
import Reports from './pages/reports';
import log from './logging';
import TokenFetcher from './pages/TokenFetcher';
import PrivateRoute from './components/PrivateRoute';
import {refreshTokens} from './pages/refreshtokens';
import {useEffect, useRef, useState} from 'react';
import {handleRevokeToken} from './components/header';
import IdleTimer from 'react-idle-timer';
import jwtDecode from 'jwt-decode';

function App() {
    console.log('inside App.js');


    const idleTimerRef = useRef(null);
        const [tokenExpired, setTokenExpired] = useState(false);

        // Check token expiration logic
        const checkTokenExpiration = () => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                const decodedToken = jwtDecode(accessToken);
                const currentTime = Date.now() / 1000; // Convert current time to seconds

                // If token is expired or about to expire in the next 5 minutes
                if (decodedToken.exp - currentTime <= 300) {
                    console.log('Access token is expired or about to expire. Refreshing token...');
                    setTokenExpired(true);
                }
            }
        };

        // Automatically refresh tokens if they expire
        useEffect(() => {
            const intervalId = setInterval(checkTokenExpiration, 60000); // Check every 60 seconds

            return () => clearInterval(intervalId); // Cleanup on unmount
        }, []);

    useEffect(() => {
        if (tokenExpired) {
            handleRefresh();
        }
    }, [tokenExpired]);

    const handleLogOutClick = async () => {
        console.log('logging out because of inactivity');
        await handleRevokeToken();
        console.log('logged out because of inactivity');
    };

    const handleRefresh = async () => {
        console.log('refreshing tokens.. last 5 mins');
        try {
            await refreshTokens(); // Assuming refreshTokens() handles token refresh logic
            console.log('refreshed tokens, from handleRefresh');
        } catch (error) {
            log.error('Error refreshing tokens:', error);
        } finally {
            setTokenExpired(false); // Reset tokenExpired state to allow future refreshes
        }
        console.log('refreshed tokens , from handleRefresh');
    };

    return (
        <div className="App">
            <IdleTimer ref={idleTimerRef} timeout={3600000} onIdle={handleLogOutClick} />


             <Switch>
                    <Route exact path="/tokenfetcher" component={TokenFetcher} />
                    <Route exact path="/">
                        <Redirect to="/home" />
                    </Route>
                 <Route exact path="/users2">
                     <Redirect to="/users" />
                 </Route>
                    <PrivateRoute exact path="/changepassword" component={ChangePassword} />
                    <PrivateRoute exact path="/info" component={Info} />
                    <PrivateRoute exact path="/home" component={HomePage} />
                    <PrivateRoute exact path="/testruns" component={TestRuns} />
                    <PrivateRoute exact path="/testruns/byid/:id" component={TestRunsById} />
                    <PrivateRoute exact path="/testspecs" component={TestSpecs} />
                    <PrivateRoute exact path="/testspecs/byid/:id" component={TestSpecById} />
                    <PrivateRoute exact path="/testspecs/create" component={CreateTestSpec} />
                    <PrivateRoute exact path="/reports/:report" component={Reports} />
                    <PrivateRoute exact path="/reports" component={Reports} />
                    <PrivateRoute exact path="/alertadministration" component={AlertAdministration} />
                    <PrivateRoute exact path="/alertadministration/:alert" component={Alert} />
                    <PrivateRoute exact path="/users" component={Users} />
                    <PrivateRoute path="/users/edit/:username" exact component={UserById} />
                    <PrivateRoute exact path="/users/new" component={NewUser} />
                    <PrivateRoute exact path="/fullsample" component={FullSample} />
                    <PrivateRoute exact path="/minimumsample" component={MinimumSample} />
             </Switch>
        </div>
    );
}

export default App;
