import {TextField} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../helpers/axios';
import UserAlertsTable from './UserAlertsTable';
import './styles/styles.scss';
import UserRolesTable from './UserRolesTable';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TopTable from '../../components/toptable';

function CreateNewUser() {
    const history = useHistory();

    const [alerts, setAlerts] = useState([]);
    const [roles, setRoles] = useState([]);

    const [usernameVal, setUsernameVal] = useState('');
    const [emailVal, setEmailVal] = useState('');
    // const [restrictVal, setRestrictVal] = useState('');
    const [allRoles, setAllRoles] = useState([]);
    const [boxVal, setBoxVal] = useState([]);
    const [allAlerts, setAllAlerts] = useState([]);
    const [boxAl, setBoxAl] = useState([]);
    const [manufacturerVal, setManufacturerVal] = useState([]);
    const [restrictVal, setRestrictVal] = useState('');
    // const [selectedManufacturers, setSelectedManufacturers] = useState([]);
    //
    // const toggleManufacturerSelection = (manufacturer) => {
    //     setSelectedManufacturers((prevSelected) =>
    //         prevSelected.includes(manufacturer)
    //             ? prevSelected.filter((item) => item !== manufacturer)
    //             : [...prevSelected, manufacturer],
    //     );
    // };


    useEffect(() => {
        getAlerts();
        getRoles();
        getManufacturer();
    }, []);

    const getManufacturer = () => {
        axios
            .get(`/FactoryREST/rest/v1/testrun/manufacturers`)
            .then((res) => {
                setManufacturerVal(res.data);
            })
            .catch((err) => {
                console.log('err:', err);
            });
    };

    const getAlerts = () => {
        axios
            .get(`/FactoryREST/rest/v1/alert`)
            .then((res) => {
                setAllAlerts(res.data);
            })
            .catch((err) => {
                console.log('err:', err);
            });
    };

    const getRoles = () => {
        axios
            .get(`/FactoryREST/rest/v1/role`)
            .then((res) => {
                setAllRoles(res.data);
            })
            .catch((err) => {
                console.log('err:', err);
            });
    };

    const handleBoxClick = (role, index) => {
        let temp = [...roles];
        if (!boxVal[index]) {
            temp.push(role);
        } else {
            temp = temp.filter((item) => item !== role);
        }
        setRoles(temp);
    };

    const handleBoxClickAl = (alert, index) => {
        let temp = [...alerts];

        if (!boxAl[index]) {
            temp.push(alert);
        } else {
            temp = temp.filter((item) => item.id !== alert.id);
        }
        setAlerts(temp);
    };

    const handleBoxVal = (value, index) => {
        const temp = [...boxVal];
        if (value) {
            temp[index] = allRoles[index];
        } else {
            temp[index] = undefined;
        }
        setBoxVal(temp);
    };

    const handleBoxAl = (value, index) => {
        const temp = [...boxAl];
        if (value) {
            temp[index] = allAlerts[index].type;
        } else {
            temp[index] = undefined;
        }
        setBoxAl(temp);
    };

    const handleClick = () => {
        const payload = {
            username: usernameVal,
            email: emailVal,
            manufacturer: restrictVal, // selectedManufacturers,
            roles: roles, // .map((item) => ({role_name: item})),
            alerts: alerts,
        };
        axios
            .post(`/FactoryREST/rest/v1/user`, payload)
            .then((res) => {
                swal(``, `${res.data.created_user_message}`, 'success', {
                    buttons: {
                        OK: true,
                    },
                }).then((value) => {
                    switch (value) {
                        case 'OK':
                            return history.push('/users');
                        default:
                    }
                });
            })
            .catch((err) => {
                console.log('Error from NewUser.js:', err);
                swal(`Something went wrong`, err.response.data.error, 'warning', {
                    buttons: {
                        OK: true,
                    },
                }).then((value) => {
                    switch (value) {
                        case 'OK':
                            return history.push('/users');
                        default:
                    }
                });
            });
    };

    return (
        <div className="nwu__item__details__container">
            <TopTable>
                <TopTable.Title>New User</TopTable.Title>
                <TopTable.Body>
                    <div className="ts__item__elements__buttons">
                        <Tooltip title="Save new user">
                            <IconButton variant="contained" color="secondary" onClick={handleClick}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </TopTable.Body>
            </TopTable>
            <div className="item__details__container__nwu">
                <div className="nwu__item__row">
                    <div className="nwu__item__elements">
                        <div className="nwu__left">
                            <p>Username</p>
                        </div>
                        <div className="nwu__right">
                            <TextField
                                type="text"
                                value={usernameVal}
                                onChange={(e) => setUsernameVal(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="nwu__item__row">
                    <div className="nwu__item__elements">
                        <div className="nwu__left">
                            <p>Email</p>
                        </div>
                        <div className="nwu__right">
                            <TextField
                                type="text"
                                value={emailVal}
                                onChange={(e) => setEmailVal(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="nwu__item__row">
                    <div className="nwu__item__elements">
                        <div className="nwu__leftrole">
                            <p>Restrict reporting to manufacturer</p>
                        </div>
                        <div className="nwu__rightrole">
                            <FormControl variant="outlined" size="small">
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={restrictVal}
                                    onChange={(e) => setRestrictVal(e.target.value)}
                                    size="small"
                                    name="all"
                                    displayEmpty
                                >
                                    {manufacturerVal.length !== 0
                                        ? manufacturerVal.map((item, i) => {
                                            return (
                                                <MenuItem key={i} value={`${item}`}>
                                                    {item === '' ? 'All' : item}
                                                </MenuItem>
                                            );
                                        })
                                        : null}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="nwu__item__row">
                    <UserRolesTable
                        roles={allRoles}
                        boxVal={boxVal}
                        handleBoxClick={handleBoxClick}
                        handleBoxVal={handleBoxVal}
                    />
                </div>
                <div className="nwu__item__row">
                    <UserAlertsTable
                        alerts={allAlerts}
                        boxAl={boxAl}
                        handleBoxClickAl={handleBoxClickAl}
                        handleBoxAl={handleBoxAl}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreateNewUser;
