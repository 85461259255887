import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setDevType, setSpecVer, setState} from '../../redux/actions/searchAction';
import axios from '../../helpers/axios';
import log from '../../logging';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    headBackground: {
        backgroundColor: '#E9ECF1',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    tableCell: {cursor: 'pointer'},
    tableCellFailed: {
        backgroundColor: '#F9CCCC',
        cursor: 'pointer',
    },
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start', // 'space-between',
        marginTop: theme.spacing(2),
    },
    pageInputContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    entries: {
        marginTop: theme.spacing(2),
    },
}));

const PostTestSpec = ({postsTS}) => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const deviceTypeStore = useSelector((state) => state.search.device_typeEP);
    const specVersionStore = useSelector((state) => state.search.specversionEP);
    const approveStateStore = useSelector((state) => state.search.stateEP);

    const [approveState, setApproveState] = useState(approveStateStore);
    const [deviceType, setDeviceType] = useState(deviceTypeStore);
    const [deviceTypeArr, setDeviceTypeArr] = useState([]);
    const [specVersion, setSpecVersion] = useState(specVersionStore);

    useEffect(() => {
        log.debug('inside TSTContent useEffect for updation of filterState');

        dispatch(setSpecVer(specVersion));
        dispatch(setDevType(deviceType));
        dispatch(setState(approveState));
    }, [dispatch, deviceType, specVersion, approveState]);

    useEffect(() => {
        log.debug('inside TRTContent useEffect triggered by changes in any of the redux store values');

        setSpecVersion(specVersionStore);
        setDeviceType(deviceTypeStore);
        setApproveState(approveStateStore);
    }, [specVersionStore, deviceTypeStore, approveStateStore]);

    useEffect(() => {
        log.debug('inside TRTContent useEffect for initial mount');

        setSpecVersion(specVersionStore);
        setDeviceType(deviceTypeStore);
        setApproveState(approveStateStore);
    }, []);

    useEffect(() => {
        axios
            .get('/FactoryREST/rest/v1/testspec/devices-type')
            .then((res) => {
                setDeviceTypeArr(
                    res.data.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    }),
                );
            })
            .catch((err) => console.log('error: ', err));
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow className={classes.headBackground}>
                        <TableCell className={classes.tableCell}>Device Type</TableCell>
                        <TableCell className={classes.tableCell}>Testspec version</TableCell>
                        <TableCell className={classes.tableCell}>State</TableCell>
                        <TableCell className={classes.tableCell}>Created by</TableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow className="tableRow" id="table_filters">
                        <TableCell className="tableRowCell">
                            <FormControl size="small" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={deviceType || ''}
                                    onChange={(e) => setDeviceType(e.target.value)}
                                    size="small"
                                    name="all"
                                    displayEmpty
                                >
                                    {deviceTypeArr.length !== 0
                                        ? deviceTypeArr.map((item) => {
                                              return (
                                                  <MenuItem value={`${item}`} key={`${item}`}>
                                                      {item === '' ? 'All' : item}
                                                  </MenuItem>
                                              );
                                          })
                                        : ''}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <TextField
                                placeholder="Search"
                                size="small"
                                value={specVersion}
                                onChange={(e) => setSpecVersion(e.target.value)}
                            />
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <FormControl size="small" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={approveState}
                                    onChange={(e) => setApproveState(e.target.value)}
                                    size="small"
                                    name="all"
                                    displayEmpty
                                >
                                    <MenuItem size="small" value={''}>
                                        All
                                    </MenuItem>
                                    <MenuItem size="small" value={'EDITABLE'}>
                                        Not approved yet
                                    </MenuItem>
                                    <MenuItem size="small" value={'APPROVABLE'}>
                                        To be approved
                                    </MenuItem>
                                    <MenuItem size="small" value={'APPROVED'}>
                                        Approved
                                    </MenuItem>
                                    <MenuItem size="small" value={'PRODUCTIVE'}>
                                        Productive
                                    </MenuItem>
                                    <MenuItem size="small" value={'DEPRECATED'}>
                                        Deprecated
                                    </MenuItem>
                                </Select>{' '}
                            </FormControl>
                        </TableCell>
                        <TableCell className="tableRowCell"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {postsTS.map((post) => {
                        return (
                            <TableRow key={post.id} onClick={() => history.push(`/testspecs/byid/${post.id}`)}>
                                <TableCell className={classes.tableCell}>{post.device_type}</TableCell>
                                <TableCell className={classes.tableCell}>{post.version}</TableCell>
                                <TableCell className={classes.tableCell}>
                                    {post.state === 'EDITABLE' && `Not yet approved`}
                                    {post.state === 'APPROVABLE' && `To be approved by ${post.approved_by}`}
                                    {post.state === 'APPROVED' && `Approved by ${post.approved_by}`}
                                    {post.state === 'PRODUCTIVE' && `Productive`}
                                    {post.state === 'DEPRECATED' && `Deprecated`}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{post.created_by}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

PostTestSpec.propTypes = {
    postsTS: PropTypes.array.isRequired,
};

export default PostTestSpec;
