import TextField from '@material-ui/core/TextField';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setStartDate} from '../../../redux/actions/reportAction';

export const StartDate = () => {
    const dispatch = useDispatch();

    const getmonth = new Date().getMonth();
    let month;
    if (getmonth.toString().length < 2) {
        month = ('0' + getmonth).slice(-2);
    } else {
        month = getmonth;
    }

    const getday = new Date().getDate();
    let day;
    if (getday.toString().length < 2) {
        day = ('0' + getday).slice(-2);
    } else {
        day = getday;
    }
    const year = new Date().getFullYear() - 1;

    const [startdatest, setStartdatest] = useState(`${year}-${month}-${day} 10:30`);

    useEffect(() => {
        const dateFromat = startdatest.replace('T', ' ');
        const secs = ':00';
        const res = dateFromat.concat(secs);
        dispatch(setStartDate(res));
    }, [dispatch, startdatest]);

    return (
        <TextField
            id="datetime-local"
            type="datetime-local"
            defaultValue={`${year}-${month}-${day}T10:30`}
            onChange={(e) => setStartdatest(e.target.value)}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};
