import TopTable from '../../components/toptable';
import axios from '../../helpers/axios';
import {TextField} from '@material-ui/core';
import {useState} from 'react';

export default function Info() {
    const [version, setVersion] = useState('');

    axios.get(`/FactoryREST/rest/v1/info/version`).then((res) => {
        setVersion(res.data);
    });

    return (
        <div>
            <TopTable>
                <TopTable.Title>Info</TopTable.Title>
                <TopTable.Body />
            </TopTable>
            <TextField label="system version" id="ts__input" type="text" value={version} disabled />
        </div>
    );
}
