import {useEffect, useState} from 'react';
import axios from '../../helpers/axios';
import TestRunsContentById from './TestRunsContentById';
import PropTypes from 'prop-types';
import TopTable from '../../components/toptable';

export default function TestRunsById({match}) {
    const [providedId, setProvidedId] = useState();
    const [deviceTypeItem, setDeviceTypeItem] = useState('');
    const [modusItem, setModusItem] = useState('');
    const [deviceIdItem, setDeviceIdItem] = useState('');
    const [timeStampItem, setTimeStampItem] = useState('');
    const [durationItem, setDurationItem] = useState('');
    const [testerSystemIdItem, setTesterSystemIdItem] = useState('');
    const [testerSoftwareVersion, setTesterSoftwareVersion] = useState('');
    const [operatorItem, setOperatorItem] = useState('');
    const [manufacturerId, setManufacturerId] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [components, setComponents] = useState([]);
    const [testSpecVersion, setTestSpecVersion] = useState('');

    useEffect(() => {
        getPostById(match.params.id);
    }, [match.params.id]);

    const timeStampWithout = timeStampItem.split('+')[0];

    const [newTimeZone, setNewTimeZone] = useState('');

    useEffect(() => {
        let temp = timeStampItem;
        if (temp.indexOf('+') > -1) {
            temp = temp.substring(temp.indexOf('+'));
        } else {
            temp = temp.substring(temp.indexOf('-'));
        }
        setNewTimeZone(temp);
    }, [timeStampItem]);

    const [allMeasurements, setAllMeasurements] = useState([]);

    const getPostById = (id) => {
        axios
            .get(`/FactoryREST/rest/v1/testrun/${id}`)
            .then((res) => {
                setComponents(res.data.contained_components);
                setProvidedId(res.data.provided_id);
                // set test values
                setModusItem(res.data.test_mode);
                setTimeStampItem(res.data.test_start_time);
                setDurationItem(res.data.test_duration);
                // set DUT values
                setDeviceTypeItem(res.data.device_under_test.type);
                setDeviceIdItem(res.data.device_under_test.dut_id);
                setOrderNumber(res.data.device_under_test.order_number);
                setManufacturerId(res.data.device_under_test.manufacturer_id);
                // set tester values
                setTestSpecVersion(res.data.tester.test_spec_version);
                setTesterSystemIdItem(res.data.tester.test_system_id);
                setTesterSoftwareVersion(res.data.tester.test_software_version);
                setOperatorItem(res.data.tester.test_operator);
                setAllMeasurements([
                    ...res.data.test_run_specs_dto.boolean_measurement_test_run_dto_list,
                    ...res.data.test_run_specs_dto.int_measurement_test_run_dto_list,
                    ...res.data.test_run_specs_dto.double_measurement_test_run_dto_list,
                    ...res.data.test_run_specs_dto.string_measurement_test_run_dto_list,
                ]);
            })
            .catch((err) => {
                console.log('err: ', err);
            });
    };

    return (
        <div className="table__container">
            <TopTable>
                <TopTable.Title>Test Run {match.params.id}</TopTable.Title>
                <TopTable.Body />
            </TopTable>
            <TestRunsContentById
                providedId={providedId}
                deviceTypeItem={deviceTypeItem}
                modusItem={modusItem}
                deviceIdItem={deviceIdItem}
                timeStampItem={timeStampWithout}
                timeZoneItem={newTimeZone}
                durationItem={durationItem}
                testerItem={testerSystemIdItem}
                testerSoftwareVersion={testerSoftwareVersion}
                operatorItem={operatorItem}
                manufacturerId={manufacturerId}
                orderNumber={orderNumber}
                allMeasurements={allMeasurements}
                components={components}
                accordingToItem={testSpecVersion}
            />
        </div>
    );
}

TestRunsById.propTypes = {
    match: PropTypes.object.isRequired,
};
