import {Component} from 'react';
import PropTypes from 'prop-types';
import {ContentState, EditorState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './emailEditor.css';

// define Typechecking properties
const propTypes = {
    emailTemplate: PropTypes.string.isRequired,
};

class EmailEditor extends Component {
    constructor(props) {
        super(props);

        const emailTemplate = this.props.emailTemplate;
        // convert HTML from backend to DraftJs ContentState to initialize the editor
        const emailTemplateContentBlock = htmlToDraft(emailTemplate);
        let editorState = null;

        if (emailTemplateContentBlock) {
            const contentState = ContentState.createFromBlockArray(emailTemplateContentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
        }

        this.state = {
            editorState: editorState,
        };
    }

    onEditorStateChange = (editorState) => {
        setState(
            {
                editorState: editorState,
            },
            () => {
                // convert editor specific syntax to an html string
                const currentContent = state.editorState.getCurrentContent();
                const emailAsString = draftToHtml(convertToRaw(currentContent));

                // notify parent component about change in editor
                props.onEmailTemplateChange(emailAsString);
            },
        );
    };

    render() {
        const {editorState} = this.state;

        return (
            <div>
                <Editor
                    editorState={editorState}
                    // toolbarClassName="toolbarClassName" // using default style
                    // wrapperClassName="wrapperClassName" // using default style
                    editorClassName="editorClassName" // look in emailEditor.css
                    onEditorStateChange={this.onEditorStateChange}
                />
            </div>
        );
    }
}

// assign propTypes to class
EmailEditor.propTypes = propTypes;

export default EmailEditor;
