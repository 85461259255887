import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {setInterval} from '../../../redux/actions/reportAction';

export const Interval = () => {
    const intervalArr = [
        {interval: 'hour'},
        {interval: 'day'},
        {interval: 'week'},
        {interval: 'month'},
        {interval: 'quarter'},
        {interval: 'year'},
    ];

    const [intervalst, setIntervalst] = useState(intervalArr[0].interval);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setInterval(intervalst));
    }, [dispatch, intervalst]);

    return (
        <FormControl size="small" className="report__input">
            <FormControl size="small" className="report__input">
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    size="small"
                    name="all"
                    value={intervalst}
                    onChange={(e) => setIntervalst(e.target.value)}
                >
                    {intervalArr.map((item, i) => {
                        return (
                            <MenuItem key={i} value={`${item.interval}`}>
                                {'Per ' + item.interval}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </FormControl>
    );
};
