import {TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../helpers/axios';
import {createDevType, createVersion} from '../../redux/actions/createTSActions';
import './styles/styles.scss';

function CreateTestSpecDetails() {
    const dispatch = useDispatch();
    const createTS = useSelector((state) => state.createTS);

    const history = useHistory();

    const submitAll = () => {
        let storedId = 0;
        axios
            .post(`/FactoryREST/rest/v1/testspec`, createTS)
            .then((response) => {
                storedId = response.data;
                swal('Done', `New testspec (${response.data}) has been created`, 'success', {
                    buttons: {
                        OK: true,
                    },
                }).then(() => history.push(`/testspecs/byid/${storedId}`));
            })
            .catch(() => {
                swal(`Something went wrong`, `Please try again`, 'warning', {
                    buttons: {
                        OK: true,
                    },
                }).then(() => history.push('/testspecs'));
            });
    };

    return (
        <div className="tsc__item__details__container">
            <div className="table__container__top">
                <div className="table__container__left">
                    <h3>Create</h3>
                </div>
                <div className="table__container__right"></div>
            </div>
            <div className="tsc__item__detail__tables">
                <div className="tsc__item__row">
                    <div className="tsc__item__elements">
                        <p>Device Type</p>
                        <TextField
                            id="tsc__input"
                            type="text"
                            variant="outlined"
                            onChange={(e) => dispatch(createDevType(e.target.value))}
                        />
                    </div>
                </div>
                <div className="tsc__item__row">
                    <div className="tsc__item__elements">
                        <p>Version (optional)</p>
                        <TextField
                            id="tsc__input"
                            type="text"
                            variant="outlined"
                            onChange={(e) => dispatch(createVersion(e.target.value))}
                        />
                    </div>
                </div>
            </div>
            <div className="tsc__item__row">
                <div className="tsc__item__elements">
                    <Button variant="contained" color="primary" onClick={submitAll}>
                        Create
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default CreateTestSpecDetails;
