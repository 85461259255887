import {useEffect, useState} from 'react';
import {Redirect} from 'react-router';
import jwtDecode from 'jwt-decode';
import {useLocation} from 'react-router-dom';
import log from '../../logging';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import axios from '../../helpers/axios';
import './HomePage.scss';

const HomePage = () => {
    log.debug('inside HomePage component');

    const location = useLocation();
    let path = location.pathname;
    const token = localStorage.getItem('accessToken');// token previously
    const decodedToken = jwtDecode(token);
    const userGroups = decodedToken['cognito:groups'] || [];
    // const [mfaNeeded, setMfaNeeded] = useState(false);
    const [emailVerified, setEmailVerified] = useState(decodedToken['email_verified'] === 'true');
    const username = decodedToken['username'];

    const [showVerifyPopup, setShowVerifyPopup] = useState(!emailVerified);
    const [showMfaSetupPopup, setShowMfaSetupPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [totpCode, setTotpCode] = useState('');
    const [secretCode, setSecretCode] = useState('');
    const [firstUseEffectCompleted, setFirstUseEffectCompleted] = useState(false); // New state to track first useEffect completion
    const [mfaGenerateMessage, setMfaGenerateMessage] = useState('Generate TOTP Secret');

    log.debug('path=', path);

    useEffect(() => {
        const checkMfaStatus = async () => {
            try {
                const response = await axios.get('/api/mfa/checkMfaStatus',
                    {params: {username: username}},
                );
                log.info('MFA Status:', response.data);
                log.info('boolean condition:', response.data.includes('MFA is not enabled'));
                // Check the response message and update the state accordingly
                if (response.data.includes('MFA is not enabled')) {
                    log.info('inside true condition');
                    setShowMfaSetupPopup(true); // Show the MFA setup popup if MFA is not enabled
                    // setMfaNeeded(true);
                    // renderMfaPopup();

                    log.warn('inside checkMfaStatus showMfaSetupPopup: ', showMfaSetupPopup);
                    // setFirstUseEffectCompleted(true);
                    // log.warn('inside checkMfaStatus mfaNeeded: ', mfaNeeded);
                }
                // setFirstUseEffectCompleted(true);
            } catch (error) {
                console.error('Error checking MFA status:', error);
                // setFirstUseEffectCompleted(true);
            } finally {
                setFirstUseEffectCompleted(true);
            }
        };
        checkMfaStatus(); // Call the async function here
    }, []);

    // useEffect to check the order
    useEffect(() => {
        if (!emailVerified) {
            log.info('inside !emailVerified case of if in useEffect ');
            // Show email verification popup if the email is not verified
            setShowVerifyPopup(true);
        } else {
            // If email is verified, check MFA status
            log.info('inside else case of if in useEffect ');
            setShowVerifyPopup(false);
            // checkMfaStatus();
        }
    }, [emailVerified]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
        setVerificationCode('');
        axios.post('/FactoryREST/rest/v1/email/send-verification-email')
            .then((response) => {
                setIsCodeSent(true);
            })
            .catch((error) => {
                console.error('Error sending verification email', error);
            });
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setVerificationCode('');
    };

    const handleVerify = () => {
        axios.post('/FactoryREST/rest/v1/email/verify-email', null, {params: {verificationCode}})
            .then((response) => {
                alert('Email verified successfully!');
                setEmailVerified(true);
                setShowVerifyPopup(false);
                setOpenDialog(false);
                setVerificationCode('');
            })
            .catch((error) => {
                alert('Failed to verify email. Please try later.');
                console.error('Error verifying email:', error);
            });
    };

    const handleMfaSetup = async () => {
        try {
            const response = await axios.post('/api/mfa/associateSoftwareToken');
            setSecretCode(response.data.secretCode);
            setMfaGenerateMessage('Re-generate TOTP Secret');
            log.info('Secret Code:', secretCode);

            // Generate QR code using the TOTP secret
            const qrCodeData =
                `otpauth://totp/Factory-Service:${decodedToken.username}`
                +`?secret=${response.data.secretCode}&issuer=YourApp`;
            setQrCode(qrCodeData);
        } catch (error) {
            console.error('Failed to associate software token:', error);
        }
    };

    const handleVerifyTotp = async () => {
        try {
            log.info('totp code:', totpCode);
            const response = await axios.post('/api/mfa/verifyTotpCode',
                {userCode: totpCode});
            log.info('TOTP verification response:', response);
            alert('TOTP verified successfully!');

            // Enable MFA
            await axios.post('/api/mfa/enableMFA', {
                username: decodedToken.username,
            });

            setShowMfaSetupPopup(false);
        } catch (error) {
            alert('Failed to verify TOTP. Please try again.');
            console.error('Error verifying TOTP:', error);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleVerifyTotp();
        }
    };


    log.debug('path=', path);

    // Render the verification popup if email_verified is false

    const renderMfaPopup = () => (
            <>
        <Modal open={showMfaSetupPopup} onClose={() => setShowMfaSetupPopup(false)}
               className="verify-email-modal">
            <div className="modal-content">
                <h2>Set up Multi-Factor Authentication (MFA)</h2>
                <Button variant="contained" color="primary" onClick={handleMfaSetup} sx={{padding: '24px 24px'}}>
                    {mfaGenerateMessage}
                </Button>
                {qrCode && (
                    <>
                        <p>Scan the QR code below with your authenticator app:</p>
                        <img src={`https://api.qrserver.com/v1/create-qr-code/?data=`
                            +`${encodeURIComponent(qrCode)}&size=200x200`} alt="TOTP QR Code" />
                        <TextField
                            label="Enter TOTP Code"
                            value={totpCode}
                            onChange={(e) => setTotpCode(e.target.value)}
                            variant="outlined" // Adds a border automatically
                            onKeyDown={handleKeyDown}
                            // fullWidth
                            sx={{
                                width: '100%',
                                mt: 3,
                                mb: 3,
                                input: {
                                    textAlign: 'center', // Center the input value
                                },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px', // Rounded corners
                                    border: '2px solid #ccc', // Custom border color
                                    '&:hover fieldset': {
                                        borderColor: '#3f51b5', // Hover color change
                                    },
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: '4px', // Adjust inner padding
                                },
                            }}
                        />
                        <Button variant="contained" color="primary" onClick={handleVerifyTotp}
                                sx={{padding: '10px 20px', width: '300px'}}>
                            Verify TOTP
                        </Button>
                    </>
                )}
            </div>
        </Modal>
            </>
    );


                const renderEmailVerificationPopup = () => (
                    <>
                <Modal open={showVerifyPopup} onClose={() => setShowVerifyPopup(false)}
                       className="verify-email-modal">
                    <div className="modal-content">
                        <h2>Verify your email address</h2>
                        <h1>Please verify your email address to be able to edit, download and generate reports
                            (depending on your role)</h1>
                        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                            Verify
                        </Button>
                    </div>
                </Modal>

                <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Verify Email</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {isCodeSent
                                ? 'Verification email sent! Please enter the code below.'
                                : 'Failed to send the verification email. Please try later.'}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Verification Code"
                            type="text"
                            fullWidth
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
                        <Button onClick={handleVerify} color="primary" disabled={!isCodeSent}>Verify</Button>
                    </DialogActions>
                </Dialog>

             </>
        );


    log.debug('path=', path);


        if (showVerifyPopup) {
            return renderEmailVerificationPopup();
        }

        if (showMfaSetupPopup) {
            return renderMfaPopup();
        }

    if (firstUseEffectCompleted) {
        if (userGroups.includes('admin') && userGroups.length === 1) {
            log.debug('inside if1 of useEffect of HomePage component');

            return <Redirect to={'/users'}/>;
        } else {
            log.debug('inside else1 of useEffect of HomePage component');

            if (!path || path === '/home') {
                path = '/testruns';
            }
            return <Redirect to={path}/>;
        }
    }

    return null;
};

export default HomePage;
