import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {setDeviceType} from '../../../redux/actions/reportAction';
import axios from '../../../helpers/axios';
import PropTypes from 'prop-types';

export default function DeviceType({optional} = {}) {
    const [deviceTypeSt, setDeviceTypeSt] = useState('');
    const [deviceTypeArr, setDeviceTypeArr] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDeviceType(deviceTypeSt));
    }, [dispatch, deviceTypeSt]);

    useEffect(() => {
        axios.get(`/FactoryREST/rest/v1/testspec/devices-type`).then((res) => {
            console.log('devicetype effect: ' + res.data);
            setDeviceTypeArr(
                res.data.sort(function (a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                }),
            );
            if (!optional) {
                setDeviceTypeSt(res.data[0] || '');
            } else {
                setDeviceTypeSt('');
            }
        });
    }, []);

    return (
        <FormControl size="small" className="report__input">
            <Select
                value={deviceTypeSt}
                onChange={(e) => {
                    setDeviceTypeSt(e.target.value);
                }}
                size="small"
                displayEmpty
            >
                {optional && (
                    <MenuItem size="small" value={''}>
                        All
                    </MenuItem>
                )}
                {deviceTypeArr.length !== 0
                    ? deviceTypeArr.map((item, i) => {
                          return (
                              <MenuItem key={i} value={`${item}`}>
                                  {item}
                              </MenuItem>
                          );
                      })
                    : ''}
            </Select>
        </FormControl>
    );
}

DeviceType.propTypes = {
    optional: PropTypes.bool.isRequired,
};
