import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ROLES} from '../../constants';
import jwtDecode from 'jwt-decode';
import {redirectUriStem} from '../PrivateRoute';

export default function Menu() {
    const token = localStorage.getItem('accessToken');// token previously
    const decoded = jwtDecode(token);
    const tokenUserGroups = decoded['cognito:groups'] || [];

    if (!token || !decoded || !tokenUserGroups) {
        localStorage.clear();
        return null;
    }

    const checkingRole = (role) => {
        const temp = [];
        role.forEach((item) => {
            ROLES.forEach((roleItem) => {
                if (roleItem.role.includes(item)) {
                    temp.push(roleItem);
                }
            });
        });

        return temp.filter((elem, index, self) => index === self.indexOf(elem));
    };

    const authorities = tokenUserGroups.filter((e) => e !== 'tester');

    const menu = checkingRole(authorities);

    const result = menu.sort((a, b) => a.id - b.id || a.name.localeCompare(b.name));

    return (
        <div>
            {result.length > 0 &&
                result.map((item) =>
                    item.external ? (
                        // Using an anchor tag for external links
                        <a
                            key={item.id}
                            href={redirectUriStem + item.path} // Ensures correct absolute URL navigation
                            className="menu__button"
                            target="_blank" // change to '_self' to open in same tab
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={item.icon} className="icon" />
                            {item.name}
                        </a>
                    ) : (
                        // Internal navigation
                        <NavLink
                            key={item.id}
                            to={item.path}
                            replace
                            className={'menu__button'}
                            activeClassName="menu__button__active"
                        >
                            <FontAwesomeIcon icon={item.icon} className="icon" />
                            {item.name}
                        </NavLink>
                    ),
                )}
        </div>
    );
}
