import {createTSConstants} from '../constans/constans';

const initialState = {
    device_type: '',
    version: '',
};

const createTSReducer = (state = initialState, action) => {
    switch (action.type) {
        case createTSConstants.CREATE_DEVTYPE:
            return {
                ...state,
                device_type: action.payload,
            };
        case createTSConstants.CREATE_VERSION:
            return {
                ...state,
                version: action.payload,
            };

        case createTSConstants.DELETE_ALL:
            return initialState;
        default:
            return state;
    }
};

export default createTSReducer;
