import Button from '@material-ui/core/Button';
import {NavLink} from 'react-router-dom';
import '../../style/global.scss';
import PropTypes from 'prop-types';

function TopTable({children}) {
    return <div className="table__container__top">{children}</div>;
}

TopTable.propTypes = {
    children: PropTypes.any,
};

TopTable.Title = (props) => (
    <div className="table__container__left">
        <h3>{props.children}</h3>
    </div>
);
TopTable.Title.displayName = 'table__container__left';
TopTable.Title.propTypes = {
    children: PropTypes.any,
};

TopTable.Body = (props) => <div className="table__container__right">{props.children}</div>;
TopTable.Body.displayName = 'table__container__right';
TopTable.Body.propTypes = {
    children: PropTypes.any,
};

TopTable.Nav = (props) => (
    <NavLink to={`${props.nav}`} style={{textDecoration: 'none'}}>
        {props.children}
    </NavLink>
);
TopTable.Nav.displayName = 'table__container__nav';
TopTable.Nav.propTypes = {
    children: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
};

TopTable.Button = (props) => (
    <Button variant="contained" color="primary" onClick={props.onClick}>
        {props.name}
    </Button>
);
TopTable.Button.displayName = 'table__container__button';
TopTable.Button.propTypes = {
    name: PropTypes.object.isRequired,
    onClick: PropTypes.object.isRequired,
};

export default TopTable;
