import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TopTable from '../../components/toptable';
import axios from '../../helpers/axios';
import '../../style/global.scss';
import Pagination from '@material-ui/lab/Pagination';
import TestRunsTableContent from './TestRunsTableContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';
import {
    setDevType,
    setSucces,
    setDut,
    setManufacturer,
    setMode,
    setSpecVer,
    setEndDateVL,
    setStartDateVL,
    setPageTestRuns,
    setSizeTestRuns,
} from '../../redux/actions/searchAction';
import ClearIcon from '@material-ui/icons/Clear';
import log from '../../logging';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import jwtDecode from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 60,
    },
    pages: {display: 'flex', alignItems: 'center', width: '15%'},
    pageInputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    entries: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        textAlign: 'right',
        width: '15%',
    },
}));

export default function TestRunsTable() {
    let manufacturer = '';
    const accessToken = localStorage.getItem('accessToken');
    if (!!accessToken) {
        log.debug('inside 1st if of TRTable functional component');

        const decodedToken = jwtDecode(accessToken);
        if (!!decodedToken['custom:manufacturer']) {
            log.debug('inside 2nd if of TRTable functional component');

            manufacturer = decodedToken['custom:manufacturer'];
        } else {
            log.debug('inside 2nd else of TRTable functional component');

            manufacturer = useSelector((state) => state.search.manufacturerEP);
        }
        }

    const deviceType = useSelector((state) => state.search.device_typeEP);
    const specVersion = useSelector((state) => state.search.specversionEP);
    const from = useSelector((state) => state.search.startDateEP);
    const to = useSelector((state) => state.search.endDateEP);
    const dut = useSelector((state) => state.search.dutEP);
    const success = useSelector((state) => state.search.successEP);
    const mode = useSelector((state) => state.search.modeEP);
    const pageTestRuns = useSelector((state) => state.search.pageTestRunsTable);
    const sizeTestRuns = useSelector((state) => state.search.sizeTestRunsTable);

    const [page, setPage] = useState(pageTestRuns);

    const [isSpinner, setIsSpinner] = useState(false);

    const dispatch = useDispatch();

    log.debug('inside entrance of TRTable functional component');
    const [posts, setPosts] = useState([]);
    const [loading] = useState(false);
    const [size, setSize] = useState(sizeTestRuns);
    const [allPages, setAllPages] = useState(0);
    const [entries, setEntries] = useState(0);
    const [inputPage, setInputPage] = useState(''); // Add this state

    const classes = useStyles();

    const clearFilters = () => {
        log.debug('clear filters button clicked');

        dispatch(setDut(''));
        dispatch(setSpecVer(''));
        dispatch(setManufacturer(''));
        dispatch(setDevType(''));
        dispatch(setSucces(''));
        dispatch(setMode(''));
        dispatch(setStartDateVL(''));
        dispatch(setEndDateVL(''));
        dispatch(setPageTestRuns(0));
        dispatch(setSizeTestRuns(10));
        setSize(10);
        setPage(0);
    };

    const handleInputChange = (event) => {
        setInputPage(event.target.value);
    };

    const goToPage = () => {
        const pageNumber = parseInt(inputPage, 10);
        if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= allPages) {
            dispatch(setPageTestRuns(pageNumber - 1));
            setPage(pageNumber - 1);
        }
    };

    const handleKeyDown = (e) => {
        log.debug('inside handleKeyDown of TRTable Page!');
        const pageNumber = parseInt(inputPage, 10);

        if (e.key === 'Enter') {
            log.debug('inside if (e.key === Enter) of handleKeyDown of TRTable Page!');

            if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= allPages) {
                dispatch(setPageTestRuns(pageNumber - 1));
                setPage(pageNumber - 1);
            }
        }
    };

    const handlePaginationChange = (e, value) => {
        dispatch(setPageTestRuns(value - 1));
        setPage(value - 1);
    };

    const handleSizeChange = (e) => {
        dispatch(setSizeTestRuns(e.target.value));
        setSize(e.target.value);
        dispatch(setPageTestRuns(0));
        setPage(0);
    };

    const getExport = () => {
            const fromObject = new Date(from);
            const toObject = new Date(to);
            const fromPart = `&from=${fromObject.getTime()}`;
            const toPart = `&to=${toObject.getTime()}`;
            const dutPart = dut ? `&dut=${dut}` : '';
            const specVersionPart = specVersion ? `&spec_version=${specVersion}` : '';
            const manufacturerPart = manufacturer ? `&manufacturer=${manufacturer}` : '';
            const successPart = success ? `&success=${success}` : '';
            const modePart = mode ? `&mode=${mode}` : '';
            const deviceTypePart = deviceType ? `&device_type=${deviceType}` : '';
            log.debug('inside TRTable useEffect for GETTING Props..the main api call');

            const url1 = `/FactoryREST/rest/v1/testrun/export?${fromPart}${toPart}`
            +`${dutPart}${specVersionPart}${manufacturerPart}`+
        `${successPart}${modePart}${deviceTypePart}`;
            setIsSpinner(true);
            axios
                .get(
                    // eslint-disable-next-line max-len
                    url1,
                    {responseType: 'blob'},
                )
                .then((response) => {
                    const file = new Blob([response.data], {
                        type: response.headers['content-type'],
                    });
                    const url = window.URL.createObjectURL(new Blob([file]));
                    const link = document.createElement('a');
                    setIsSpinner(false);
                    link.href = url;
                    link.setAttribute('download', 'TestRunExport.csv');

                    document.body.appendChild(link);
                    link.click();
                });
    };

    useEffect(() => {
        log.debug(`the filter values from within TRT useffect for component update and api call:
  Device Type: ${deviceType}
  Spec Version: ${specVersion}
  Manufacturer: ${manufacturer}
  From: ${from}
  To: ${to}
  DUT: ${dut}
  Success: ${success}
  Mode: ${mode}
`);
        const fromObject = new Date(from);
        const toObject = new Date(to);
        const fromPart = `&from=${fromObject.getTime()}`;
        const toPart = `&to=${toObject.getTime()}`;
        const dutPart = dut ? `&dut=${dut}` : '';
        const specVersionPart = specVersion ? `&spec_version=${specVersion}` : '';
        const manufacturerPart = manufacturer ? `&manufacturer=${manufacturer}` : '';
        const successPart = success ? `&success=${success}` : '';
        const modePart = mode ? `&mode=${mode}` : '';
        const deviceTypePart = deviceType ? `&device_type=${deviceType}` : '';
        log.debug('inside TRTable useEffect for GETTING Props..the main api call');

        const url = `/FactoryREST/rest/v1/testrun?page=${pageTestRuns}&size=${sizeTestRuns}${fromPart}${
            toPart}${dutPart}${specVersionPart}${manufacturerPart}${successPart}${modePart}${deviceTypePart}`;

        log.debug(`API call path: ${url}`);
        axios
            .get(url)
            .then((res) => {
                log.debug('in the then block of axios');
                if (page >= res.data.total_pages) {
                    log.debug('in the if within then block of axios');
                    // setPage(pageTestRuns);
                    setPage(0);
                    dispatch(setPageTestRuns(0));
                    log.debug('in the end of if within then block of axios');
                }
                setAllPages(res.data.total_pages);
                setEntries(res.data.total_elements);
                setPosts(res.data.content);
            })
            .catch(() => {
                log.debug('in the catch block of axios');
                setPosts([]);
                log.debug('in the end of catch block of axios');
            });
    }, [deviceType, dut, from, manufacturer, mode, pageTestRuns, sizeTestRuns, specVersion, success, to]);

    return (
        <div className="table__container">
            <TopTable>
                <TopTable.Title>Test Runs</TopTable.Title>
                <TopTable.Body>
                    <Tooltip title="Export as CSV">
                        <IconButton name="Export" onClick={getExport} variant="contained" color="secondary">
                            {isSpinner ? (
                                <CircularProgress style={{color: 'red'}} size={25} thickness={5} />
                            ) : (
                                <PublishIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Clear all filters">
                        <IconButton name="Clear" onClick={clearFilters} variant="contained" color="secondary">
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </TopTable.Body>
            </TopTable>
            <TestRunsTableContent posts={posts} loading={loading} />
            <div className={classes.pagination}>
                <div className={classes.pages}>
                    Rows per page:
                    <FormControl size="small" className={classes.formControl}>
                        <Select value={size} onChange={handleSizeChange} size="small" name="all" displayEmpty>
                            <MenuItem size="small" value={10}>
                                10
                            </MenuItem>
                            <MenuItem size="small" value={25}>
                                25
                            </MenuItem>
                            <MenuItem size="small" value={50}>
                                50
                            </MenuItem>
                            <MenuItem size="small" value={100}>
                                100
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Pagination
                    count={allPages}
                    page={page + 1}
                    color="secondary"
                    onChange={handlePaginationChange}
                    hidePrevButton
                    hideNextButton
                />
                <div className={classes.pageInputContainer}>
                    <TextField
                        type="number"
                        value={inputPage}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Page number"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            inputProps: {min: 1, max: allPages},
                            style: {height: '30px'}, // Adjust height here
                        }}
                        style={{width: '80px', marginLeft: '8px', marginRight: '8px'}}
                    />
                    <Button
                        onClick={goToPage}
                        variant="contained"
                        color="secondary"
                        style={{
                            maxWidth: '30px',
                            maxHeight: '25px',
                            minWidth: '30px',
                            minHeight: '25px',
                        }}
                    >
                        Go
                    </Button>
                </div>
                <div className={classes.entries}>Entries: {entries}</div>
            </div>
        </div>
    );
}
