import log from 'loglevel';

const env = 'development';

if (env === 'development') {
    log.setLevel('debug');
} else if (env === 'production') {
    log.setLevel('error');
}
export default log;
