import {
    faClipboardList,
    faCloudUploadAlt,
    faRulerCombined,
    faUserFriends,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

export const ROLES = [
    {
        path: '/testruns',
        icon: faCloudUploadAlt,
        name: 'Test Runs',
        id: 0,
        role: ['sphinxQA', 'manufacturerQA', 'admin'],
    },
    {
        path: '/testspecs',
        icon: faRulerCombined,
        name: 'Test Specs',
        role: ['sphinxQA', 'manufacturerQA', 'admin'],
        id: 1,
    },
    {
        path: '/reports',
        icon: faClipboardList,
        name: 'Reports',
        role: ['sphinxQA', 'manufacturerQA', 'admin'],
        id: 2,
    },
    {
        path: '/users',
        icon: faUserFriends,
        name: 'Users',
        role: ['admin'],
        id: 4,
    },
    {
        path: '/info',
        icon: faInfoCircle,
        name: 'Info',
        role: ['admin', 'sphinxQA', 'manufacturerQA'],
        id: 5,
    },
];
