import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {setIntervalEinz} from '../../../redux/actions/reportAction';

export const EinzelnerInterval = () => {
    const intervalArr = [{interval: 1}, {interval: 10}, {interval: 0.01}, {interval: 0.001}];

    const [intervaleinzst, setIntervaleinzst] = useState(intervalArr[0].interval);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIntervalEinz(intervaleinzst));
    }, [dispatch, intervaleinzst]);

    return (
        <FormControl size="small" className="report__input">
            <FormControl size="small" className="report__input">
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    size="small"
                    name="all"
                    value={intervaleinzst}
                    onChange={(e) => setIntervaleinzst(e.target.value)}
                >
                    {intervalArr.map((item, i) => {
                        return (
                            <MenuItem key={i} value={`${item.interval}`}>
                                {item.interval}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </FormControl>
    );
};
