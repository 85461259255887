import {createTSConstants} from '../constans/constans';

export function createDevType(payload) {
    return {
        type: createTSConstants.CREATE_DEVTYPE,
        payload,
    };
}

export function createVersion(payload) {
    return {
        type: createTSConstants.CREATE_VERSION,
        payload,
    };
}

export function createMeas(payload) {
    return {
        type: createTSConstants.CREATE_MEAS,
        payload,
    };
}

export function deleteAllMeas(payload) {
    return {
        type: createTSConstants.DELETE_ALL,
        payload,
    };
}
