import {useEffect, useState} from 'react';
import axios from '../../../helpers/axios';

function FullSample() {
    const specId = localStorage.getItem('specId');

    const [dataJS, setDataJS] = useState('');

    useEffect(() => {
        axios
            .get(`/FactoryREST/rest/v1/testspec/${specId}/sample`)
            .then((res) => {
                setDataJS(res.data);
            })
            .catch(() => {});
    }, [specId]);

    return <pre style={{textAlign: 'left'}}>{JSON.stringify(dataJS, null, 2)}</pre>;
}

export default FullSample;
