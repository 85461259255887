import PropTypes from 'prop-types';

function ReportRow(props) {
    return (
        <div className="container__data">
            <div className="row__reports">{props.children}</div>
        </div>
    );
}
ReportRow.propTypes = {
    children: PropTypes.array.isRequired,
};

ReportRow.Left = (props) => <div className="row__reports__left">{props.children}</div>;
ReportRow.Left.displayName = 'row__reports__left';
ReportRow.Left.propTypes = {
    children: PropTypes.object.isRequired,
};

ReportRow.Right = (props) => <div className="row__reports__right">{props.children}</div>;
ReportRow.Right.displayName = 'row__reports__right';
ReportRow.Right.propTypes = {
    children: PropTypes.object.isRequired,
};

export default ReportRow;
