import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import axios from '../../../helpers/axios';
import {setMode} from '../../../redux/actions/reportAction';

export const Mode = () => {
    const getModes = async () => {
        const res = await axios.get(`/FactoryREST/rest/v1/report/modes`);
        return setModesArr(res.data);
    };

    const [modest, setModest] = useState('');
    const [modesArr, setModesArr] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getModes();
    }, []);

    useEffect(() => {
        dispatch(setMode(modest));
    }, [dispatch, modest]);

    return (
        <FormControl size="small" className="report__input">
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={modest}
                onChange={(e) => setModest(e.target.value)}
                size="small"
                name="all"
                displayEmpty
            >
                <MenuItem size="small" value={''}>
                    All
                </MenuItem>
                {modesArr.length !== 0
                    ? modesArr.map((item, i) => {
                          return (
                              <MenuItem key={i} value={`${item}`}>
                                  {item}
                              </MenuItem>
                          );
                      })
                    : ''}
            </Select>
        </FormControl>
    );
};
