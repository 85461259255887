import {useEffect, useState} from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TopTable from '../../components/toptable';
import axios from '../../helpers/axios';
import TestSpecsTableContent from './TestSpecsTableContent';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {useDispatch, useSelector} from 'react-redux';
import log from '../../logging';
import {setDevType, setSpecVer, setState, setPageTestSpecs} from '../../redux/actions/searchAction';
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bottomContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        marginTop: theme.spacing(2),
        position: 'relative',
    },
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        marginTop: theme.spacing(2),
        position: 'relative',
    },
    flexRightHalf: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    pageInputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(4),
    },
    entries: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
}));

function TestSpecsTable() {
    const deviceType = useSelector((state) => state.search.device_typeEP);
    const specVersion = useSelector((state) => state.search.specversionEP);
    const approveState = useSelector((state) => state.search.stateEP);
    const pageTestSpecs = useSelector((state) => state.search.pageTestSpecsTable);

    const [page, setPage] = useState(pageTestSpecs);
    const [postsTS, setPostsTS] = useState([]);
    const [size] = useState(10);
    const [allPages, setAllPages] = useState(0);
    const [entries, setEntries] = useState(0);
    const [inputPage, setInputPage] = useState(''); // Add this state

    const dispatch = useDispatch();
    const classes = useStyles();

    const handlePageClick = (event, value) => {
        dispatch(setPageTestSpecs(value - 1));
        setPage(value - 1);
    };

    const handleInputChange = (event) => {
        setInputPage(event.target.value);
    };

    const goToPage = () => {
        const pageNumber = parseInt(inputPage, 10);
        if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= allPages) {
            dispatch(setPageTestSpecs(pageNumber - 1));
            setPage(pageNumber - 1);
        }
    };

    const handleKeyDown = (e) => {
        log.debug('inside handleKeyDown of Login Page!');
        const pageNumber = parseInt(inputPage, 10);

        if (e.key === 'Enter') {
            log.debug('inside if (e.key === Enter) of handleKeyDown of Login Page!');

            if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= allPages) {
                dispatch(setPageTestSpecs(pageNumber - 1));
                setPage(pageNumber - 1);
            }
        }
    };

    useEffect(() => {
        setPage(pageTestSpecs);
    }, [pageTestSpecs]);

    const clearFilters = () => {
        log.debug('clear filters button clicked');

        dispatch(setSpecVer(''));
        dispatch(setDevType(''));
        dispatch(setState(''));
        dispatch(setPageTestSpecs(0));
    };

    useEffect(() => {
        const specVersionPart = specVersion ? `&spec_version=${specVersion}` : '';
        const deviceTypePart = deviceType ? `&device_type=${deviceType}` : '';
        const statePart = approveState ? `&state=${approveState}` : '';
        log.debug('inside TRTable useEffect for GETTING Props..the main api call');

        const url = `/FactoryREST/rest/v1/testspec?page=${page}&size=${size}${specVersionPart}${
            statePart}${deviceTypePart}`;
        axios
            .get(
                // eslint-disable-next-line max-len
                url,
            )
            .then((res) => {
                if (page >= res.data.total_pages) {
                    setPage(0);
                    dispatch(setPageTestSpecs(0));
                }
                setAllPages(res.data.total_pages);
                setEntries(res.data.total_elements);
                setPostsTS(res.data.content);
            })
            .catch(() => {
                setPostsTS([]);
            });
    }, [deviceType, approveState, page, size, specVersion, inputPage]);

    return (
        <div className="table__container">
            <TopTable>
                <TopTable.Title>Test Specs</TopTable.Title>
                <TopTable.Body>
                    <TopTable.Nav nav={'testspecs/create'}>
                        <Tooltip title="Add new test spec">
                            <IconButton variant="contained" color="secondary">
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </TopTable.Nav>
                    <Tooltip title="Clear all filters">
                        <IconButton name="Clear" onClick={clearFilters} variant="contained" color="secondary">
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </TopTable.Body>
            </TopTable>

            <TestSpecsTableContent postsTS={postsTS} />
            <div className={classes.bottomContainer}>
                <div className={classes.entries}>Entries: {entries}</div>
                <div className={classes.paginationContainer}>
                    <Pagination
                        count={allPages}
                        page={page + 1}
                        color="secondary"
                        onChange={handlePageClick}
                        hidePrevButton
                        hideNextButton
                    />
                </div>

                <div className={classes.pageInputContainer}>
                    <TextField
                        type="number"
                        value={inputPage}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Page number"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            inputProps: {min: 1, max: allPages},
                            style: {height: '30px'}, // Adjust height here
                        }}
                        style={{width: '80px', marginLeft: '8px', marginRight: '8px'}}
                    />
                    <Button
                        onClick={goToPage}
                        variant="contained"
                        color="secondary"
                        style={{maxWidth: '30px', maxHeight: '25px', minWidth: '30px', minHeight: '25px'}}
                    >
                        Go
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default TestSpecsTable;
