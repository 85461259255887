import axios from './helpers/axios';
import log from './logging';
export let logoutUrl = '';
export const fetchCognitoConfig = async () => {
    try {
        const response = await axios.get('/api/config/cognito');
        logoutUrl = `${response.data.domain}/logout?response_type=code&client_id=${response.data.clientId}&`
        + `scope=${response.data.scopes}&redirect_uri=`;
    } catch (error) {
        console.error('Error fetching Cognito configuration:', error);
    }
    log.debug('from within configuration.js, logoutUrl=', logoutUrl);
};
