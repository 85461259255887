import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: window.location.origin,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');// token previously
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (!originalConfig.url.includes('/oauth/token') && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                const refreshToken = localStorage.getItem('refreshToken');
                if (refreshToken) {
                    try {
                        const uname = 'clientId';
                        const pass = 'secret';
                        const rs = await axiosInstance.post(
                            `/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`,
                            {
                                auth: {
                                    username: uname,
                                    password: pass,
                                },
                            },
                        );

                        const {accessToken} = rs.data;

                        localStorage.setItem('accessToken', accessToken);// token previously

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
        }

        return Promise.reject(err);
    },
);

export default axiosInstance;
