import {searchConstants} from '../constans/constans';
import jwtDecode from 'jwt-decode';
import log from '../../logging';

const newDate = new Date();
const year = newDate.getFullYear();
const start = new Date(`01/01/2019`);
const end = new Date(`12/31/${year}`);
const initialState = {
    device_typeEP: '',
    specversionEP: '',
    manufacturerEP: '',
    startDateEP: start.toISOString(),
    dutEP: '',
    endDateEP: end.toISOString(),
    successEP: '',
    modeEP: '',
    createdbyEP: '',
    approvedbyEP: '',
    usernameEP: '',
    roleEP: '',
    stateEP: '',
    pageTestSpecsTable: 0,
    pageTestRunsTable: 0,
    pageUsers: 0,
    sizeTestRunsTable: 10,
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case searchConstants.DUT:
            log.debug('DUT of reducer called');
            return {
                ...state,
                dutEP: action.payload !== '' ? action.payload : '',
            };
        case searchConstants.PAGE_TEST_SPECS:
            log.debug('PAGE_TEST_SPECS of reducer called');
            return {
                ...state,
                pageTestSpecsTable: action.payload !== 0 ? action.payload : 0,
            };
        case searchConstants.PAGE_TEST_RUNS:
            log.debug('PAGE_TEST_RUNS of reducer called');
            return {
                ...state,
                pageTestRunsTable: action.payload !== 0 ? action.payload : 0,
            };
        case searchConstants.PAGE_USERS:
            log.debug('PAGE_USERS of reducer called');
            return {
                ...state,
                pageUsers: action.payload !== 0 ? action.payload : 0,
            };
        case searchConstants.SIZE_TEST_RUNS:
            log.debug('SIZE_TEST_RUNS of reducer called');
            return {
                ...state,
                sizeTestRunsTable: action.payload,
            };
        case searchConstants.SET_SPECVER:
            log.debug('SET_SPECVER of reducer called');
            return {
                ...state,
                specversionEP: action.payload !== '' ? action.payload : '',
            };

        case searchConstants.MANUFACTURER:
            log.debug('MANUFACTURER of reducer called');

            const token = localStorage.getItem('accessToken');// token previously
            const decodedToken = jwtDecode(token);


            // Extract the custom Authorities attribute from the token
            const decodedMnf = decodedToken['custom:manufacturer'] || '';
            log.debug('from searchReducer decodedMnf=', decodedMnf);

// Convert the string into an array by splitting it (assuming it's comma-separated)

            if (decodedMnf !== '') {
                log.debug('from searchReducer in if (decodedMnf !== \'\') decodedMnf=', decodedMnf);

                return {
                    ...state,
                    manufacturerEP: action.payload !== '' ? decodedMnf : '', // decodedMnf, // decodedToken.allowed_reporting_manufacturers,
                };
            } else {
                log.debug('from searchReducer in else decodedMnf=', decodedMnf, 'action.payload=', action.payload);

                return {
                    ...state,
                    manufacturerEP: action.payload !== '' ? action.payload : '',
                };
            }

        case searchConstants.START_DATE:
            log.debug('received payload for START_DATE=', action.payload);

            return {
                ...state,
                startDateEP: action.payload !== '' ? action.payload : start.toISOString(),
            };
        case searchConstants.END_DATE:
            log.debug('received payload for END_DATE=', action.payload);

            return {
                ...state,
                endDateEP: action.payload !== '' ? action.payload : end.toISOString(),
            };
        case searchConstants.SET_DEVTYPE:
            log.debug('SET_DEVTYPE of reducer called');
            return {
                ...state,
                device_typeEP: (() => {
                    if (action.payload !== '') {
                        log.debug('if block of devtype return => state is now', action.payload);
                        return action.payload;
                    } else {
                        log.debug('else block of devtype return => state is now', '');
                        return '';
                    }
                })(),
            };
        case searchConstants.SUCCESS:
            log.debug('SUCCESS of reducer called');

            return {
                ...state,
                successEP: action.payload !== '' ? action.payload : '',
            };
        case searchConstants.MODE:
            log.debug('MODE of reducer called');

            return {
                ...state,
                modeEP: action.payload !== '' ? action.payload : '',
            };
        case searchConstants.CREATEDBY:
            log.debug('CREATEDBY of reducer called');

            return {
                ...state,
                createdbyEP: action.payload !== '' ? `&created_by=${action.payload}` : '',
            };
        case searchConstants.APPROVEDBY:
            log.debug('APPROVEDBY of reducer called');

            return {
                ...state,
                approvedbyEP: action.payload !== '' ? `&approved_by=${action.payload}` : '',
            };
        case searchConstants.USER_NAME:
            log.debug('USER_NAME of reducer called');

            return {
                ...state,
                usernameEP: action.payload !== '' ? action.payload : '',
            };
        case searchConstants.ROLE:
            log.debug('ROLE of reducer called');
            log.debug(action.payload);

            return {
                ...state,
                roleEP: action.payload !== '' ? action.payload : '',
            };
        case searchConstants.STATE:
            log.debug('STATE of reducer called');

            return {
                ...state,
                stateEP: action.payload !== '' ? action.payload : '',
            };
        case searchConstants.SEARCH_LOGOUT:
            log.debug('SEARCH_LOGOUT of reducer called');
            return initialState; // Reset state to initial state on logout

        default:
            return state;
    }
};

export default searchReducer;
