import React, {useEffect} from 'react';
import axios from '../../helpers/axios';
import log from '../../logging';


// Define refreshTokens as a normal async function
export const refreshTokens = async () => {
    try {
        log.debug('Starting token refresh process');
        const refreshToken = localStorage.getItem('refreshToken'); // Get the refresh token from localStorage

        if (!refreshToken) {
            console.error('No refresh token found in localStorage');
            return;
        }

        // Making API call to refresh token using backend API
        const response = await axios.post('/api/refreshtoken', {
            refreshToken: refreshToken, // Sending refresh token in the request body
        }, {
            withCredentials: true,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });

        if (response.status === 200) {
            log.debug('Tokens refreshed successfully:', response.data);

            const accessToken = response.data.access_token;
            const idToken = response.data.id_token;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('idToken', idToken);
        } else {
            console.error('Failed to refresh token. Status:', response.status);
        }
    } catch (error) {
        console.error('Error refreshing token:', error.response ? error.response.data : error.message);
    }
};
const TokenRefresherComponent = () => {
    useEffect(() => {
        // Call your async function on component mount
        refreshTokens();
    }, []); // Empty dependency array ensures it runs only once on mount

    return <div>Refreshing Tokens...</div>;
};

export default TokenRefresherComponent;
