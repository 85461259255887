import {reportConstants} from '../constans/constans';

const initialState = {
    tester: '',
    manufacturer: '',
    devicetype: '',
    mode: '',
    interval: '',
    startdate: '',
    enddate: '',
    user: '',
    testspecversion: '',
    deviceid: '',
    intervaleinz: '',
    measurement: '',
    second_measurement: '',
    third_measurement: '',
    fourth_measurement: '',
    fifth_measurement: '',
    test_success: '',
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case reportConstants.SET_TESTER:
            return {
                ...state,
                tester: action.payload,
            };
        case reportConstants.SET_MANUFACTURER:
            return {
                ...state,
                manufacturer: action.payload,
            };
        case reportConstants.SET_DEVICE_TYPE:
            return {
                ...state,
                devicetype: action.payload,
            };
        case reportConstants.SET_MODE:
            return {
                ...state,
                mode: action.payload,
            };
        case reportConstants.SET_INTERVAL:
            return {
                ...state,
                interval: action.payload,
            };
        case reportConstants.SET_START_DATE:
            return {
                ...state,
                startdate: action.payload,
            };
        case reportConstants.SET_END_DATE:
            return {
                ...state,
                enddate: action.payload,
            };
        case reportConstants.SET_USER:
            return {
                ...state,
                user: action.payload,
            };
        case reportConstants.SET_TESTSPEC_VERSION:
            return {
                ...state,
                testspecversion: action.payload,
            };
        case reportConstants.SET_DEVICE_ID:
            return {
                ...state,
                deviceid: action.payload,
            };
        case reportConstants.SET_INTERVAL_EINZ:
            return {
                ...state,
                intervaleinz: action.payload,
            };
        case reportConstants.SET_MEASUREMENT:
            return {
                ...state,
                measurement: action.payload,
            };
        default:
            return state;
    }
};

export default reportReducer;
