import {useEffect, useState} from 'react';
import Pagination from '@material-ui/lab/Pagination';
import TopTable from '../../components/toptable';
import {setPageUsers} from '../../redux/actions/searchAction';
import axios from '../../helpers/axios';
import '../../style/global.scss';
import UsersTableContent from './UsersTableContent';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {useDispatch, useSelector} from 'react-redux';// useDispatch
import log from '../../logging';

export default function UsersTable() {
    log.debug('inside UsersTable');
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [size] = useState(10);
    const [allPages, setAllPages] = useState(0);
    const username = useSelector((state) => state.search.usernameEP);
    const role = useSelector((state) => state.search.roleEP);
    const pageStore = useSelector((state) => state.search.pageUsers);
    const [page, setPage] = useState(pageStore);
    let res = [];

    const handlePageClick = (event, value) => {
        setPage(value - 1);
        dispatch(setPageUsers(value - 1));
    };

    useEffect(()=> {
        dispatch(setPageUsers(page));
    }, [page, dispatch]);

    // API call for users data
    useEffect(() => {
        // setUsers([]);
        const fetchUsers = async () => {
            log.debug(`Fetching users with params - page: ${page}, username: ${username}, role: ${role}`);
            // setUsers([]);
            try {
                setUsers([]);
                res = await axios.get(
                    `/FactoryREST/rest/v1/user?page=${page}&size=${size}&role=${role}&username=${username}`,
                );
                setUsers(res.data.content);
                if (page > res.data.total_pages) {
                    setPage(0);
                }
                setAllPages(res.data.total_pages);

                log.debug('Fetched users:', res.data.content);
            } catch (err) {
                log.error('Error fetching users:', err);
            }
        };

        fetchUsers();
    }, [page, size, username, role]);


    return (
        <div className="table__container">
            <TopTable>
                <TopTable.Title>Users</TopTable.Title>
                <TopTable.Body>
                    <TopTable.Nav nav={'/users/new'}>
                        <Tooltip title="Add new user">
                            <IconButton variant="contained" color="secondary">
                                <PersonAddIcon />
                            </IconButton>
                        </Tooltip>
                    </TopTable.Nav>
                </TopTable.Body>
            </TopTable>

            <UsersTableContent users={users}/>

            <Pagination
                count={allPages}
                page={page + 1}
                color="secondary"
                onChange={handlePageClick}
                hidePrevButton
                hideNextButton
            />
        </div>
    );
}
