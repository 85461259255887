import './styles/styles.scss';
import Menu from './ReportsTableMenu';
import TopTable from '../../components/toptable';
import PropTypes from 'prop-types';
import {reports} from './Reports';
import SingleMeasurement from './singleMeasurement';
import Measurements from './measurements';
import ProductionVolume from './productionVolume';
import ProductHistory from './productHistory';
import FirstPassYield from './firstPassYield';
import TesterUtilization from './testerUtilization';

function ReportsTable({report}) {
    return (
        <div style={{width: '100%'}}>
            <div style={{width: '15%', display: 'inline-block', verticalAlign: 'top', marginRight: '3%'}}>
                <TopTable>
                    <TopTable.Title>Reports</TopTable.Title>
                    <TopTable.Body />
                </TopTable>
                <Menu activeReport={report} />
            </div>
            <div style={{width: '82%', display: 'inline-block', verticalAlign: 'top'}}>
                {reports[0].path === report && <SingleMeasurement />}
                {reports[1].path === report && <Measurements />}
                {reports[2].path === report && <ProductionVolume />}
                {reports[3].path === report && <ProductHistory />}
                {reports[4].path === report && <FirstPassYield />}
                {reports[5].path === report && <TesterUtilization />}
            </div>
        </div>
    );
}
ReportsTable.propTypes = {
    report: PropTypes.string.isRequired,
};
export default ReportsTable;
