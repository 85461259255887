import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import './styles/styles.scss';
import {useHistory} from 'react-router-dom';
import {reports} from './Reports';
import PropTypes from 'prop-types';
import {redirectUriStem} from '../../components/PrivateRoute';

const useStyles = makeStyles((theme) => ({
    table: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #eef1f5',
        borderRadius: '5px',
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headBackground: {
        backgroundColor: '#E9ECF1',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    tableCell: {
        'cursor': 'pointer',
        '&:hover': {
            background: '#f2f6f9',
        },
        '&.Mui-selected': {
            background: '#f2f6f9',
        },
    },
    tableCellFailed: {
        backgroundColor: '#F9CCCC',
        cursor: 'pointer',
    },
    buttonContainer: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
    },
    grafanaButton: {
        backgroundColor: '#d90429',
        color: '#fff',
        fontWeight: 'bold',
        padding: theme.spacing(1, 3),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '14px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#a90321',
        },
    },
}));

function ReportsTableMenu({activeReport}) {
    const classes = useStyles();
    const history = useHistory();

    return (
<div>
        <Table className={classes.table} aria-label="simple table">
            <TableBody>
                {reports.map((report) => {
                    return (
                        <TableRow
                            selected={activeReport === report.path}
                            key={report.id}
                            className={classes.tableCell}
                            onClick={() => history.push(`/reports/${report.path}`)}
                            hover={true}
                        >
                            <TableCell className={classes.tableCell}>{report.title}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    {/* "Go to Grafana" Button */}
    <div className={classes.buttonContainer}>
        <Button
            variant="contained"
            className={classes.grafanaButton}
            onClick={() => window.open(redirectUriStem+'/grafana/dashboards', '_blank')}
        >
            Grafana Reports
        </Button>
    </div>

</div>
    );
}
ReportsTableMenu.propTypes = {
    activeReport: PropTypes.string.isRequired,
};
export default ReportsTableMenu;
