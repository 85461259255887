import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
// import log from '../../logging';

function TokenFetcher() {
    const history = useHistory();

    useEffect(() => {
        console.log('inside useEffect of TokenFetcher');
        if (!!!localStorage.getItem('accessToken')) {
            console.log('inside if of useEffect of TokenFetcher');
            fetchTokensFromUrl();
        }
        history.push('/'); // Redirect to your desired route
    }, []);

    function fetchTokensFromUrl() {
        console.log('inside fetchTokensFromUrl of TokenFetcher');
        const hashParams = new URLSearchParams(window.location.hash.substring(1));// search
        const accessToken = hashParams.get('accessToken');
        const idToken = hashParams.get('idToken');
        const refreshToken = hashParams.get('refreshToken');
        console.log(window.location.hash);
        console.log(`accessToken=${accessToken}`);
        // Access Cognito groups
        const decodedToken = jwtDecode(accessToken);

        const userGroups = decodedToken['cognito:groups'];
        // Extract the custom Authorities attribute from the token

// Convert the string into an array by splitting it (assuming it's comma-separated)
        console.log(userGroups); // This will log an array of group names
        console.log(`refreshToken=${refreshToken}`);
        console.log(`idToken=${idToken}`);
        if (accessToken && idToken) {
            console.log('inside if (accessToken && idToken)');
            // Store tokens in localStorage
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('idToken', idToken);
            if (refreshToken) {
                console.log('inside if refreshToken');

                localStorage.setItem('refreshToken', refreshToken);
            }
            // Redirect to a protected route after storing tokens
        }
    }

     return (
        <div>
            {/* <h1></h1>*/}
        </div>
     );
}

export default TokenFetcher;
