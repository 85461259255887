import {reportConstants} from '../constans/constans';

export function setTester(payload) {
    return {
        type: reportConstants.SET_TESTER,
        payload,
    };
}

export function setManufacturer(payload) {
    return {
        type: reportConstants.SET_MANUFACTURER,
        payload,
    };
}

export function setDeviceType(payload) {
    return {
        type: reportConstants.SET_DEVICE_TYPE,
        payload,
    };
}

export function setMode(payload) {
    return {
        type: reportConstants.SET_MODE,
        payload,
    };
}

export function setInterval(payload) {
    return {
        type: reportConstants.SET_INTERVAL,
        payload,
    };
}

export function setStartDate(payload) {
    return {
        type: reportConstants.SET_START_DATE,
        payload,
    };
}

export function setEndDate(payload) {
    return {
        type: reportConstants.SET_END_DATE,
        payload,
    };
}

export function setUser(payload) {
    return {
        type: reportConstants.SET_USER,
        payload,
    };
}

export function setTestSpecversion(payload) {
    return {
        type: reportConstants.SET_TESTSPEC_VERSION,
        payload,
    };
}

export function setDeviceId(payload) {
    return {
        type: reportConstants.SET_DEVICE_ID,
        payload,
    };
}

export function setIntervalEinz(payload) {
    return {
        type: reportConstants.SET_INTERVAL_EINZ,
        payload,
    };
}

export function setMeasurement(payload) {
    return {
        type: reportConstants.SET_MEASUREMENT,
        payload,
    };
}

export function setSecondMeasurement(payload) {
    return {
        type: reportConstants.SET_SECOND_MEASUREMENT,
        payload,
    };
}

export function setThirdMeasurement(payload) {
    return {
        type: reportConstants.SET_THIRD_MEASUREMENT,
        payload,
    };
}

export function setFourthMeasurement(payload) {
    return {
        type: reportConstants.SET_FOURTH_MEASUREMENT,
        payload,
    };
}

export function setFifthMeasurement(payload) {
    return {
        type: reportConstants.SET_FIFTH_MEASUREMENT,
        payload,
    };
}

export function setTestSuccess(payload) {
    return {
        type: reportConstants.SET_TEST_SUCCESS,
        payload,
    };
}
