import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import 'date-fns';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setEndDateVL, setStartDateVL} from '../../redux/actions/searchAction';
import './dateRange.scss';
import log from '../../logging';

function DateRange() {
    log.debug('inside entrance of dateRange component index.js');

    const dispatch = useDispatch();

    const fromStore = useSelector((state) => state.search.startDateEP);
    const toStore = useSelector((state) => state.search.endDateEP);

    // the local variables for storing start and end dates
    const [startDate, setStartDate] = useState(new Date(fromStore));
    const [endDate, setEndDate] = useState(new Date(toStore));

    useEffect(() => {
        setStartDate(new Date(fromStore));
        setEndDate(new Date(toStore));
    }, []);

    // Restore date range from redux store when the component mounts
    useEffect(() => {
        setStartDate(new Date(fromStore));
        setEndDate(new Date(toStore));
    }, [fromStore, toStore]);

    const handleStartChange = (date) => {
        const startDateObject = new Date(date);
        setStartDate(startDateObject);
    };
    const handleEndChange = (date) => {
        const endDateObject = new Date(date);
        setEndDate(endDateObject);
    };

    useEffect(() => {
        dispatch(setStartDateVL(startDate.toISOString()));

        dispatch(setEndDateVL(endDate.toISOString()));
    }, [dispatch, startDate, endDate]);

    return (
        <div className={'container__picker'}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                    <DatePicker
                        autoOk={true}
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={startDate}
                        onChange={handleStartChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
            <div className={'container__picker__to'}>to</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                    <DatePicker
                        autoOk={true}
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        value={endDate}
                        onChange={handleEndChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default DateRange;
