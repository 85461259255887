import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../helpers/axios';
import TopTable from '../../components/toptable';
import './ConfigurationOverrides.scss';
import jwtDecode from 'jwt-decode';
import swal from 'sweetalert';

export default function Configuration() {
    const [dataFormat, setDataFormat] = useState('dot'); // Default to dot-separated decimals
    const history = useHistory();

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        const decodedToken = jwtDecode(accessToken);
        const username = decodedToken.username;

        axios.get(`/FactoryREST/rest/v1/user/${username}`)
            .then((response) => {
                setDataFormat(response.data.configuration || 'dot'); // Use Cognito value
            })
            .catch((error) => {
                console.error('Error fetching data format:', error);
                swal('Error', 'Could not fetch current configuration.', 'error');
            });
    }, []);


    const handleSave = async () => {
        const accessToken = localStorage.getItem('accessToken');
        const decodedToken = jwtDecode(accessToken);
        const username = decodedToken.username;

        try {
            // Fetch user details from Cognito before updating
            const userResponse = await axios.get(`/FactoryREST/rest/v1/user/${username}`);
            const userData = userResponse.data;

            // Ensure all required attributes are sent in the update request
            const updatePayload = {
                username: username,
                email: userData.email, // Preserve existing email
                roles: userData.roles, // Preserve roles
                manufacturer: userData.manufacturer, // Preserve manufacturer
                manufacturers: userData.manufacturers, // Preserve manufacturer list
                alerts: userData.alerts, // Preserve alerts
                configuration: dataFormat, // Only this is updated
            };

            await axios.post('/FactoryREST/rest/v1/user/update-user-settings', updatePayload);

            console.log('Settings saved successfully');
            swal('Success', 'Configuration saved successfully!', 'success');
        } catch (error) {
            console.error('Error saving data format:', error);
            swal('Error', 'Configuration could not be saved. Please try again.', 'error');
        }
    };


    return (
        <div className="configuration-container">
            <TopTable>
                <TopTable.Title>Settings</TopTable.Title>
            </TopTable>

            <div className="configuration-content">
                <h3>Data Format</h3>
                <p>Select the format in which data is downloaded from the desired reports.</p>

                <RadioGroup
                    value={dataFormat}
                    onChange={(e) => setDataFormat(e.target.value)}
                >
                    <FormControlLabel
                        value="comma"
                        control={<Radio />}
                        label="Comma Separated Decimals (####,00)"
                    />
                    <FormControlLabel
                        value="dot"
                        control={<Radio />}
                        label="Dot Separated Decimals (####.00)"
                    />
                </RadioGroup>

                <div className="button-group">
                    <Button variant="outlined" onClick={() => history.push('/home')}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
}
