import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

function reportExportButtons({handleSave, isLoading}) {
    const classes = useStyles();
    if (!isLoading) {
        return (
            <>
                <Button variant="contained" color="secondary" onClick={() => handleSave('pdf')}>
                    PDF
                </Button>
                {/*
            <Button variant="contained" color="secondary" onClick={() => handleSave('html')}>
                HTML
            </Button>
            */}
                <Button variant="contained" color="secondary" onClick={() => handleSave('xlsx')}>
                    XLSX
                </Button>
            </>
        );
    } else {
        return (
            <div className={classes.root}>
                <LinearProgress color="secondary" style={{height: '6px'}} />
            </div>
        );
    }
}

export default reportExportButtons;
