import {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {TextField, Fade, Modal, Backdrop, Checkbox, Button} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(5, 10, 8),
        width: '400px',
    },
}));

export default function MeasurementValue({item, action, editable, openDialog, onClose, onChange, onSave, onDelete}) {
    // action = [Add, Edit]
    /* item = {
    name: "String",
    id: 0,
    type: "String" || "Integer" || "Boolean", || "Double",
    min: 0,
    max: 1,
    unit: "unit",
    required: true || false,
    valid_value: true || false || "regex",
  }*/
    // regex expressions
    const regexForFloat = /^[-]?\d+(\.\d+)?$/;
    const regexForDecimal = /^\d+$/;
    const classes = useStyles();
    const [open] = useState(openDialog);
    const [minValueError, setMinValueError] = useState(false);
    const [maxValueError, setMaxValueError] = useState(false);

    function handleRegexOfValue(eventParam) {
        let match = false;
        switch (item.type) {
            case 'Integer':
                if (!(eventParam.target.value === '' || regexForDecimal.test(eventParam.target.value))) {
                    match = true;
                }
                break;
            case 'Double':
                if (!(eventParam.target.value === '' || regexForFloat.test(eventParam.target.value))) {
                    match = true;
                }
                break;
            default:
            // do nothing
        }
        switch (eventParam.target.id) {
            case 'minValue':
                setMinValueError(match);
                break;
            case 'maxValue':
                setMaxValueError(match);
                break;
            default:
        }
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => {
                    onClose(false);
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h4>{action + ' ' + item.type}</h4>
                        <TextField
                            label="Name"
                            type="text"
                            variant="standard"
                            size="small"
                            value={item.name}
                            onChange={(e) => onChange({...item, name: e.target.value})}
                            disabled={editable}
                        />
                        {item.type === 'Integer' || item.type === 'Double' ? (
                            <TextField
                                label={'Min Value' + (minValueError ? ': The input value is not a number' : '')}
                                id="minValue"
                                size="small"
                                type="text"
                                value={item.min}
                                error={minValueError}
                                onChange={(e) => {
                                    handleRegexOfValue(e);
                                    const value = e.target.value;
                                    onChange({...item, min: value});
                                }}
                                disabled={editable}
                            />
                        ) : null}
                        {item.type === 'Integer' || item.type === 'Double' ? (
                            <TextField
                                label={'Max Value' + (maxValueError ? ': The input value is not a number' : '')}
                                id="maxValue"
                                size="small"
                                type="text"
                                value={item.max}
                                error={maxValueError}
                                onChange={(e) => {
                                    handleRegexOfValue(e);
                                    const value = e.target.value;
                                    onChange({...item, max: value});
                                }}
                                disabled={editable}
                            />
                        ) : null}
                        {item.type === 'Integer' || item.type === 'Double' ? (
                            <TextField
                                label="Unit"
                                size="small"
                                type="text"
                                value={item.unit}
                                onChange={(e) => onChange({...item, unit: e.target.value})}
                                disabled={editable}
                            />
                        ) : null}
                        <div style={{display: 'flex'}}>
                            <p>Required</p>
                            <Checkbox
                                checked={item.required}
                                onChange={(e) => onChange({...item, required: e.target.checked})}
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                                disabled={editable}
                            />
                        </div>
                        {item.type === 'String' ? (
                            <TextField
                                type="text"
                                label="Valid measured value"
                                variant="standard"
                                value={item.valid_value}
                                onChange={(e) => onChange({...item, valid_value: e.target.value})}
                                disabled={editable}
                            />
                        ) : null}
                        {item.type === 'Boolean' ? (
                            <div style={{display: 'flex'}}>
                                <p>Valid Value</p>
                                <Checkbox
                                    defaultChecked={item.valid_value}
                                    value={item.valid_value}
                                    onChange={(e) => onChange({...item, valid_value: e.target.checked})}
                                    color="primary"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                    disabled={editable}
                                />
                            </div>
                        ) : null}
                        <div style={{display: 'flex'}}>
                            <Button
                                style={{marginTop: 10}}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if ((minValueError || maxValueError) === true) {
                                        return;
                                    }
                                    onSave(item);
                                }}
                                disabled={editable}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    onDelete(item.id);
                                }}
                                style={{marginLeft: 10, marginTop: 10}}
                                disabled={editable}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

MeasurementValue.propTypes = {
    item: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    editable: PropTypes.object.isRequired,
    openDialog: PropTypes.object.isRequired,
    onClose: PropTypes.object.isRequired,
    onChange: PropTypes.object.isRequired,
    onSave: PropTypes.object.isRequired,
    onDelete: PropTypes.object.isRequired,
};
