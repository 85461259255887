import {TextField} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../helpers/axios';
import UserAlertsTable from './UserAlertsTable';
import './styles/styles.scss';
import UserRolesTable from './UserRolesTable';
import PropTypes from 'prop-types';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TopTable from '../../components/toptable';

export default function EditUser({usernameMatch}) {
    const history = useHistory();

    const [alerts, setAlerts] = useState([]);
    const [roles, setRoles] = useState([]);

    const [usernameVal, setUsernameVal] = useState('');
    const [emailVal, setEmailVal] = useState('');
    const [restrictVal, setRestrictVal] = useState('');
    const [allRoles, setAllRoles] = useState([]);
    const [boxVal, setBoxVal] = useState([]);
    const [allAlerts, setAllAlerts] = useState([]);
    const [boxAl, setBoxAl] = useState([]);
    const [manufacturerVal, setManufacturerVal] = useState([]);

    useEffect(() => {
        getUserById(usernameMatch);
    }, [usernameMatch]);

    const getUserById = (username) => {
        axios
            .get(`/FactoryREST/rest/v1/user/${username}`)
            .then((res) => {
                setUsernameVal(res.data.username);
                setEmailVal(res.data.email);
                setRestrictVal(res.data.manufacturer);
                setRoles(res.data.roles);
                setAlerts(res.data.alerts);

                axios
                    .get(`/FactoryREST/rest/v1/role`)
                    .then((res1) => {
                        setAllRoles(res1.data);
                        const tempRoles = [...boxVal];
                        res1.data.map((role, index) => {
                            if (JSON.stringify(res.data.roles).indexOf(role) > 0) {
                                tempRoles.splice(index, 1, role);
                            } else {
                                tempRoles[index] = undefined;
                            }
                        });
                        setBoxVal(tempRoles);
                    })
                    .catch((err) => {
                        console.log('err:', err);
                    });
                axios
                    .get(`/FactoryREST/rest/v1/alert`)
                    .then((res2) => {
                        setAllAlerts(res2.data);
                        const tempAlerts = [...boxAl];
                        res2.data.map((alert, index) => {
                            if (JSON.stringify(res.data.alerts).indexOf(alert.type) > 0) {
                                tempAlerts.splice(index, 1, alert.type);
                            } else {
                                tempAlerts[index] = undefined;
                            }
                        });
                        setBoxAl(tempAlerts);
                    })
                    .catch((err) => {
                        console.log('err:', err);
                    });
            })
            .catch((err) => {
                console.log('err:', err);
            });

        axios
            .get(`/FactoryREST/rest/v1/testrun/manufacturers`)
            .then((res) => {
                setManufacturerVal(res.data);
            })
            .catch((err) => {
                console.log('err:', err);
            });
    };

    const handleBoxClick = (role, index) => {
        let temp = [...roles];
        if (!boxVal[index]) {
            temp.push(role);
        } else {
            temp = temp.filter((item) => item !== role);
        }
        setRoles(temp);
    };

    const handleBoxClickAl = (alert, index) => {
        let temp = [...alerts];

        if (!boxAl[index]) {
            temp.push(alert);
        } else {
            temp = temp.filter((item) => item.id !== alert.id);
        }
        setAlerts(temp);
    };

    const handleBoxVal = (value, index) => {
        const temp = [...boxVal];
        if (value) {
            temp[index] = allRoles[index];
        } else {
            temp[index] = undefined;
        }
        setBoxVal(temp);
    };

    const handleBoxAl = (value, index) => {
        const temp = [...boxAl];
        if (value) {
            temp[index] = allAlerts[index].type;
        } else {
            temp[index] = undefined;
        }
        setBoxAl(temp);
    };

    const handleSave = () => {
        const payload = {
            username: usernameVal,
            email: emailVal,
            manufacturer: restrictVal,
            roles: roles, // .map((item) => ({role_name: item})),
            alerts: alerts,
            test: 'testpassed',
        };
        console.log(payload);
        axios
            .put(`/FactoryREST/rest/v1/user`, payload)
            .then(() => {
                swal('Done', 'User has been updated', 'success', {
                    buttons: {
                        OK: true,
                    },
                }).then((value) => {
                    switch (value) {
                        case 'OK':
                            return history.push('/users');
                        default:
                    }
                });
            })
            .catch((err) => {
                console.log('Error: ', err);
                swal(`Something went wrong`, err.response.data.error, 'warning', {
                    buttons: {
                        OK: true,
                    },
                }).then((value) => {
                    switch (value) {
                        case 'OK':
                            return history.push('/users');
                        default:
                    }
                });
            });
    };

    const handleDelete = () => {
        swal({
            title: 'Are you sure?',
            text: 'Deleting users cant be undone!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios
                    .delete(`/FactoryREST/rest/v1/user/${usernameMatch}`)
                    .then((res) => {
                        swal('User has been deleted!', {
                            icon: 'success',
                        });
                        return history.push('/users');
                    })
                    .catch(() => {
                        swal(`Something went wrong`, `Please try again`, 'warning', {
                            buttons: {
                                OK: true,
                            },
                        }).then((value) => {
                            switch (value) {
                                case 'OK':
                                    return history.push('/users');
                                default:
                            }
                        });
                    });
            }
        });
    };

    return (
        <div className="nwu__item__details__container">
            <TopTable>
                <TopTable.Title>Edit User</TopTable.Title>
                <TopTable.Body>
                    <div className="ts__item__elements__buttons">
                        <Tooltip title="Save user">
                            <IconButton variant="contained" color="secondary" onClick={handleSave}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete user">
                            <IconButton variant="contained" color="secondary" onClick={handleDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </TopTable.Body>
            </TopTable>
            <div className="item__details__container__nwu">
                <div className="nwu__item__row">
                    <div className="nwu__item__elements">
                        <div className="nwu__left">
                            <p>Username</p>
                        </div>
                        <div className="nwu__right">
                            <TextField
                                type="text"
                                value={usernameVal}
                                onChange={(e) => setUsernameVal(e.target.value)}
                                inputProps={{readOnly: true}}
                            />
                        </div>
                    </div>
                </div>
                <div className="nwu__item__row">
                    <div className="nwu__item__elements">
                        <div className="nwu__left">
                            <p>Email</p>
                        </div>
                        <div className="nwu__right">
                            <TextField
                                type="text"
                                value={emailVal}
                                onChange={(e) => setEmailVal(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="nwu__item__row">
                    <div className="nwu__item__elements">
                        <div className="nwu__leftrole">
                            <p>Restrict reporting to manufacturer</p>
                        </div>
                        <div className="nwu__rightrole">
                            <FormControl variant="outlined" size="small">
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={restrictVal || ''}
                                    onChange={(e) => setRestrictVal(e.target.value)}
                                    size="small"
                                    name="all"
                                    displayEmpty
                                >
                                    {manufacturerVal.length !== 0
                                        ? manufacturerVal.map((item, i) => {
                                              return (
                                                  <MenuItem key={i} value={`${item}`}>
                                                      {item === '' ? 'All' : item}
                                                  </MenuItem>
                                              );
                                          })
                                        : null}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="nwu__item__row">
                    <UserRolesTable
                        roles={allRoles}
                        boxVal={boxVal}
                        handleBoxClick={handleBoxClick}
                        handleBoxVal={handleBoxVal}
                    />
                </div>
                <div className="nwu__item__row">
                    <UserAlertsTable
                        alerts={allAlerts}
                        boxAl={boxAl}
                        handleBoxClickAl={handleBoxClickAl}
                        handleBoxAl={handleBoxAl}
                    />
                </div>
            </div>
        </div>
    );
}

EditUser.propTypes = {
    usernameMatch: PropTypes.object.isRequired,
};
