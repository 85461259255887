import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {deleteAllMeas} from '../../redux/actions/createTSActions';
import CreateTestSpecsDetails from './CreateTestSpecsDetails';

function CreateTestSpec() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(deleteAllMeas());
    }, [dispatch]);

    return <CreateTestSpecsDetails />;
}

export default CreateTestSpec;
