import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import axios from '../../../helpers/axios';
import PropTypes from 'prop-types';
import {
    setMeasurement,
    setSecondMeasurement,
    setThirdMeasurement,
    setFourthMeasurement,
    setFifthMeasurement,
    setTestSuccess,
} from '../../../redux/actions/reportAction';

export function Measurement({optional, number, deviceType} = {}) {
    const [measurementsArr, setMeasurementsArr] = useState([]);
    const [measurementsSt, setMeasurementsSt] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (number === 1) {
            dispatch(setMeasurement(measurementsSt));
        }
        if (number === 2) {
            dispatch(setSecondMeasurement(measurementsSt));
        }
        if (number === 3) {
            dispatch(setThirdMeasurement(measurementsSt));
        }
        if (number === 4) {
            dispatch(setFourthMeasurement(measurementsSt));
        }
        if (number === 5) {
            dispatch(setFifthMeasurement(measurementsSt));
        }
    }, [dispatch, measurementsSt]);

    useEffect(() => {
        if (deviceType !== '' && typeof deviceType != 'undefined') {
            axios.get(`FactoryREST/rest/v1/report/meassurements/${deviceType}`).then((res) => {
                setMeasurementsArr(
                    res.data.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    }),
                );
                if (!optional) {
                    setMeasurementsSt(res.data[0] || '');
                } else {
                    setMeasurementsSt('');
                }
            });
        }
    }, [deviceType, number]);

    return (
        <FormControl size="small" className="report__input">
            <FormControl size="small" className="report__input">
                <Select
                    size="small"
                    displayEmpty
                    value={measurementsSt}
                    onChange={(e) => {
                        setMeasurementsSt(e.target.value);
                    }}
                >
                    {optional && number === 1 && (
                        <MenuItem size="small" value={''}>
                            All
                        </MenuItem>
                    )}
                    {measurementsArr.length !== 0
                        ? measurementsArr.map((item, i) => {
                              return (
                                  <MenuItem key={i} value={`${item}`}>
                                      {item}
                                  </MenuItem>
                              );
                          })
                        : ''}
                </Select>
            </FormControl>
        </FormControl>
    );
}

Measurement.propTypes = {
    deviceType: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    optional: PropTypes.bool.isRequired,
};

export function TestSuccess() {
    const [testSuccessSt, setTestSuccessSt] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTestSuccess(testSuccessSt));
    }, [dispatch, testSuccessSt]);

    return (
        <FormControl size="small" className="report__input">
            <FormControl size="small" className="report__input">
                <Select
                    size="small"
                    displayEmpty
                    value={testSuccessSt}
                    onChange={(e) => {
                        setTestSuccessSt(e.target.value);
                    }}
                >
                    <MenuItem size="small" value={''}>
                        All
                    </MenuItem>
                    <MenuItem size="small" value={true}>
                        True
                    </MenuItem>
                    <MenuItem size="small" value={false}>
                        False
                    </MenuItem>
                </Select>
            </FormControl>
        </FormControl>
    );
}
