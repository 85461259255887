import {TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import jwtDecode from 'jwt-decode';
import {useEffect, useState, useRef, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../helpers/axios';
import './styles/styles.scss';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useFilePicker} from 'use-file-picker';
import MeasurementValue from './MeasurementValue';
import TopTable from '../../components/toptable';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PanToolIcon from '@material-ui/icons/PanTool';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import log from '../../logging';
import {setDevType, setSpecVer, setState} from '../../redux/actions/searchAction';

export default function TestSpecsContentById({theTestSpec, id}) {
    log.debug('line before .getItem');

    const token = localStorage.getItem('accessToken');// only token previously

    log.debug('line after .getItem');


    const decoded = jwtDecode(token);
    const history = useHistory();
    const dispatch = useDispatch();

    const clearFilters = () => {
        log.debug('inside clear filters function');

        dispatch(setSpecVer(''));
        dispatch(setDevType(''));
        dispatch(setState(''));
    };

    const getApprovers = async () => {
        const res = await axios.get(`/FactoryREST/rest/v1/testspec/approvers`);
        return setApprovers(
            res.data.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            }),
        );
    };

    const [approvers, setApprovers] = useState([]);
    const [approver, setApprover] = useState('');

    useEffect(() => {
        log.debug('line before .getApprovers');

        getApprovers();

        log.debug('line after .getApprovers');
    }, []);

    const [userNameEditable] = useState(decoded.username);

    const [measurementSpecVal, setMeasurementSpecVal] = useState(theTestSpec.measurement_specs);
    const handleSaving = useRef(false);

    const handleSave = useCallback(() => {
        const payload = {
            id: theTestSpec.id,
            device_type: theTestSpec.device_type,
            user_name: theTestSpec.user_name,
            version: theTestSpec.version,
            measurement_specs: measurementSpecVal,
            approved_by: userNameEditable,
        };
        axios
            .put(`/FactoryREST/rest/v1/testspec/${id}`, payload)
            .then((response) => {
                setMeasurementSpecVal(response.data.measurement_specs);
                swal('Done', 'Testspec has been updated', 'success', {
                    buttons: {
                        OK: true,
                    },
                }).then(() => history.push(`/testspecs/byid/${id}`));
            })
            .catch((err) => {
                console.log('err:', err);
                swal(`Something went wrong`, `Please try again`, 'warning', {
                    buttons: {
                        OK: true,
                    },
                }).then(() => history.push('/testspecs'));
            });
    }, [
        history,
        id,
        measurementSpecVal,
        theTestSpec.device_type,
        theTestSpec.id,
        theTestSpec.user_name,
        theTestSpec.version,
        userNameEditable,
    ]);

    useEffect(
        function handleSaveOfSpec() {
            if (handleSaving.current !== false) {
                handleSaving.current = false;
                handleSave();
            }
        },
        [measurementSpecVal, handleSave],
    );

    const [measurementItem, setMeasurementItem] = useState({
        name: '',
        id: null,
        type: null,
        min: null,
        max: null,
        unit: null,
        required: false,
        valid_value: false,
    });
    const [action, setAction] = useState('Add');
    const [visible, setVisible] = useState(false);
    const openModalWithType = (t) => {
        const item = {
            name: '',
            type: t,
            min: '',
            max: '',
            unit: '',
            valid_value: '',
            required: false,
        };

        openModalWithItem(item);
    };
    const openModalWithItem = (item) => {
        setVisible(true);
        setMeasurementItem({...item});
    };

    const [fileUploaded, setfileUploaded] = useState(false);
    const [openFileSelector, {filesContent, loading, errors}] = useFilePicker({
        multiple: false,
        accept: '.json',
    });

    useEffect(
        function handleImportTestSpec() {
            if (filesContent.length > 0 && !loading && errors.length === 0 && !fileUploaded) {
                setfileUploaded(true);
                setMeasurementSpecVal(JSON.parse(filesContent[0].content));
                handleSaving.current = true;
            }
        },
        [filesContent.length, loading, errors.length, fileUploaded, filesContent, handleSave],
    );

    const approveSpec = (state) => {
        log.warn('approver: {}', approver);
        log.warn('state: {}', state);
        log.warn('theTestSpec.id: {}', theTestSpec.id);
        axios
            .put(
                '/FactoryREST/rest/v1/testspec/' +
                    theTestSpec.id +
                    '/state/?=&new_state=' +
                    state +
                    (approver ? `&user_name=${approver}` : ``),
            )
            .then(() => {
                swal('Done', `State of testspec (id: ${theTestSpec.id}) is updated`, 'success', {
                    buttons: {
                        OK: true,
                    },
                }).then(() => {
                    history.push(`/testspecs/byid/${theTestSpec.id}`);
                    window.location.reload();
                });
            })
            .catch(() => {
                swal(`Something went wrong`, `Please try again`, 'warning', {
                    buttons: {
                        OK: true,
                    },
                });
            });
    };

    const exportSpec = () => {
        const json = JSON.stringify(measurementSpecVal);
        const blob = new Blob([json], {type: 'application/json'});
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = 'TestSpec_' + theTestSpec.device_type + '_' + theTestSpec.version + '.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const copySpec = () => {
        const specs = measurementSpecVal.map((spec) => {
            if (spec.id === measurementItem.id) {
                if (measurementItem.type === 'String' || measurementItem.type === 'Boolean') {
                    return {
                        ...spec,
                        name: measurementItem.name,
                        required: measurementItem.required,
                        valid_value: measurementItem.valid,
                    };
                } else {
                    return {
                        ...spec,
                        name: measurementItem.name,
                        min: measurementItem.min,
                        max: measurementItem.max,
                        unit: measurementItem.unit,
                        required: measurementItem.required,
                    };
                }
            } else {
                return {...spec};
            }
        });

        const payload = {
            device_type: theTestSpec.device_type,
            version: theTestSpec.version,
            user_name: theTestSpec.user_name,
            measurement_specs: specs,
        };
        axios
            .post(`/FactoryREST/rest/v1/testspec`, payload)
            .then((response) => {
                swal('Copy of Testspec', `New testspec (id: ${response.data}) has been created`, 'success', {
                    buttons: {
                        OK: true,
                    },
                }).then(() => {
                    history.push(`/testspecs/byid/${response.data}`);
                    window.location.reload();
                });
            })
            .catch(() => {
                swal(`Something went wrong`, `Please try again`, 'warning', {
                    buttons: {
                        OK: true,
                    },
                });
            });
    };

    const deleteItem = (itemId) => {
        const filterdMeasurementList = measurementSpecVal.filter((item) => item.id !== itemId);
        setMeasurementSpecVal(filterdMeasurementList);
        handleSaving.current = true;
    };

    const deleteSpec = () => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, manufacturers will not be able to upload data related to this spec!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios
                    .delete(`/FactoryREST/rest/v1/testspec/${id}`)
                    .then(() => {
                        swal('Warning! Your spec has been deleted!', {
                            icon: 'success',
                        });
                        clearFilters();
                        history.push(`/testspecs`);
                    })
                    .catch((err) => {
                        swal(`Something went wrong`, `Please try again`, 'warning', {
                            buttons: {
                                OK: true,
                            },
                        });
                        console.log('err:', err);
                    });
            }
        });
    };

    const deprecateSpec = () => {
        swal({
            title: 'Are you sure?',
            text: 'Once marked as deprecated, manufacturers will not be able to upload data related to this spec!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                approveSpec('DEPRECATED');
                swal('Warning! Your spec has been marked as deprecated!', {
                    icon: 'success',
                });
            }
        });
    };

    const addSpec = (item) => {
        let itemId = null;
        if (item.id !== null) {
            itemId = item.id;
        }
        const filterdMeasurementList = measurementSpecVal.filter((measItem) => measItem.id !== itemId);
        setMeasurementSpecVal([...filterdMeasurementList, {...item, id: itemId}]);
        handleSaving.current = true;
    };

    localStorage.setItem('specId', theTestSpec.id);

    return (
        <div className="ts__item__details__container">
            <TopTable>
                <TopTable.Title>Test Spec {id}</TopTable.Title>
                <TopTable.Body>
                    <div className="ts__item__elements__buttons">
                        <Tooltip title="Copy">
                            <IconButton variant="contained" color="secondary" onClick={copySpec}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Export">
                            <IconButton variant="contained" color="secondary" onClick={exportSpec}>
                                <PublishIcon />
                            </IconButton>
                        </Tooltip>
                        {theTestSpec.state === 'EDITABLE' && userNameEditable === theTestSpec.created_by && (
                            <Tooltip title="Import">
                                <IconButton variant="contained" color="secondary" onClick={() => openFileSelector()}>
                                    <GetAppIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {theTestSpec.state === 'EDITABLE' && userNameEditable === theTestSpec.created_by && (
                            <Tooltip title="Delete">
                                <IconButton variant="contained" color="secondary" onClick={deleteSpec}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </TopTable.Body>
            </TopTable>
            <div className="ts__item__detail__tables">
                <div className="ts__item__row">
                    <div className="ts__item__elements">
                        <TextField
                            label="device type"
                            value={theTestSpec.device_type}
                            id="ts__input"
                            type="text"
                            disabled
                        />
                    </div>
                    <div className="ts__item__elements">
                        <TextField label="version" value={theTestSpec.version} id="ts__input" type="text" disabled />
                    </div>
                    <div className="ts__item__elements">
                        <TextField
                            id="ts__input"
                            label="created by"
                            type="text"
                            value={theTestSpec.created_by}
                            disabled
                        />
                    </div>
                </div>
                <div className="ts__item__row">
                    <div className="ts__item__elements">
                        <TextField
                            label="state"
                            id="ts__input"
                            type="text"
                            value={
                                {
                                    EDITABLE: `Not approved yet`,
                                    APPROVABLE: `To be approved`,
                                    APPROVED: `Approved`,
                                    PRODUCTIVE: `Productive `,
                                    DEPRECATED: `Deprecated `,
                                }[theTestSpec.state]
                            }
                            disabled
                        />
                    </div>
                    {theTestSpec.state !== 'EDITABLE' && (
                        <div className="ts__item__elements">
                            <TextField
                                label="approved by"
                                id="ts__input"
                                type="text"
                                value={theTestSpec.approved_by}
                                disabled
                            />
                        </div>
                    )}
                    <div className="ts__item__elements">
                        {theTestSpec.state === 'EDITABLE' && userNameEditable === theTestSpec.created_by && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => approveSpec('APPROVABLE')}
                                startIcon={<ThumbsUpDownIcon />}
                            >
                                approve by
                            </Button>
                        )}
                        {theTestSpec.state === 'EDITABLE' && userNameEditable === theTestSpec.created_by && (
                            <Select value={approver} color="secondary" onChange={(e) => setApprover(e.target.value)}>
                                {approvers.length !== 0
                                    ? approvers.filter((item) => item !== userNameEditable) // Filter out the specific string
                                      .map((item, i) => {
                                          return (
                                              <MenuItem key={i} value={`${item}`}>
                                                  {item}
                                              </MenuItem>
                                          );
                                      })
                                    : ''}
                            </Select>
                        )}
                        {theTestSpec.state === 'APPROVABLE' && userNameEditable === theTestSpec.approved_by && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => approveSpec('APPROVED')}
                                startIcon={<ThumbUpIcon />}
                            >
                                approve
                            </Button>
                        )}
                        {theTestSpec.state === 'APPROVABLE' && userNameEditable === theTestSpec.approved_by && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => approveSpec('EDITABLE')}
                                startIcon={<ThumbDownIcon />}
                            >
                                revoke
                            </Button>
                        )}
                        {theTestSpec.state === 'APPROVABLE' ||
                            (theTestSpec.state === 'APPROVED' && userNameEditable === theTestSpec.created_by && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => approveSpec('EDITABLE')}
                                    startIcon={<EditIcon />}
                                >
                                    edit
                                </Button>
                            ))}
                        {theTestSpec.state === 'PRODUCTIVE' && userNameEditable === theTestSpec.created_by && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => deprecateSpec()}
                                startIcon={<PanToolIcon />}
                            >
                                deprecate
                            </Button>
                        )}
                    </div>
                </div>
                <div className="ts__item__row">
                    <div className="ts__item__elements__buttons">
                        <div>export json sample:</div>
                        <Button variant="contained" color="secondary" onClick={() => window.open(`#/fullsample`)}>
                            full sample
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => window.open(`#/minimumsample`)}>
                            minimum sample
                        </Button>
                    </div>
                </div>

                {theTestSpec.state === 'EDITABLE' && userNameEditable === theTestSpec.created_by ? (
                    <div className="ts__item__row">
                        <div className="ts__item__elements__buttons">
                            <div>add measurement:</div>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setAction('Add');
                                    openModalWithType('Boolean');
                                }}
                            >
                                boolean
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setAction('Add');
                                    openModalWithType('String');
                                }}
                            >
                                string
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setAction('Add');
                                    openModalWithType('Double');
                                }}
                            >
                                double
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="item__details__container">
                <Table>
                    <TableHead>
                        <TableRow className="item__device__tables">
                            <TableCell className="item__details__cell">
                                <h3>Name</h3>
                            </TableCell>
                            <TableCell className="item__details__cell">
                                <h3>Required</h3>
                            </TableCell>
                            <TableCell className="item__details__cell">
                                <h3>Type</h3>
                            </TableCell>
                            <TableCell className="item__details__cell">
                                <h3>Valid Values</h3>
                            </TableCell>
                            <TableCell className="item__details__cell">
                                <h3>Unit</h3>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {measurementSpecVal.length !== 0
                            ? measurementSpecVal
                                  .sort(function (a, b) {
                                      if (a.name < b.name) {
                                          return -1;
                                      }
                                      if (a.name > b.name) {
                                          return 1;
                                      }
                                      return 0;
                                  })
                                  .map(function (item) {
                                      return (
                                          <TableRow
                                              key={item.id}
                                              onClick={() => {
                                                  if (
                                                      theTestSpec.state === 'EDITABLE' &&
                                                      userNameEditable === theTestSpec.created_by
                                                  ) {
                                                      setAction('Edit');
                                                      openModalWithItem(item);
                                                  }
                                              }}
                                              style={{cursor: 'pointer'}}
                                          >
                                              <TableCell className="item__details__cell__body">
                                                  <div>{item.name}</div>
                                              </TableCell>
                                              <TableCell className="item__details__cell__body">
                                                  {`${item.required}`}
                                              </TableCell>
                                              <TableCell className="item__details__cell__body">{item.type}</TableCell>
                                              <TableCell className="item__details__cell__body">
                                                  {Number.isFinite(item.min) && Number.isFinite(item.max)
                                                      ? `[${item.min}-${item.max}]`
                                                      : `${item.valid_value}`}
                                              </TableCell>
                                              <TableCell className="item__details__cell__body">{item.unit}</TableCell>
                                          </TableRow>
                                      );
                                  })
                            : null}
                    </TableBody>
                </Table>
                {visible ? (
                    <MeasurementValue
                        action={action}
                        item={measurementItem}
                        openDialog={visible}
                        onClose={setVisible}
                        onChange={setMeasurementItem.bind(this)}
                        onSave={addSpec.bind(this)}
                        onDelete={deleteItem.bind(this)}
                    />
                ) : null}
            </div>
        </div>
    );
}

TestSpecsContentById.propTypes = {
    theTestSpec: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};
