import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import swal from 'sweetalert';
import TopTable from '../../components/toptable';
import {CognitoIdentityProviderClient, ChangePasswordCommand} from '@aws-sdk/client-cognito-identity-provider';
import '../../style/global.scss';
import axios from '../../helpers/axios';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import './ChangePasswordOverrides.scss';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

export default function ChangePassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [awsRegion, setAwsRegion] = useState(null);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [incorrectPasswordError, setIncorrectPasswordError] = useState('');
    const history = useHistory();

    const [passwordConditions, setPasswordConditions] = useState({
        lengthValid: false,
        upperCaseValid: false,
        lowerCaseValid: false,
        numberValid: false,
        specialCharValid: false,
        passwordsMatch: false,
    });

    useEffect(() => {
        axios.get('/api/config/region')
            .then((response) => {
                setAwsRegion(response.data.region);
            })
            .catch((error) => {
                console.error('Error fetching AWS region:', error);
            });
    }, []);

    useEffect(() => {
        checkPasswordConditions(newPassword, confirmedPassword);
    }, [newPassword, confirmedPassword]);

    const checkPasswordConditions = (password, confirmPassword) => {
        setPasswordConditions({
            lengthValid: password.length >= 8,
            upperCaseValid: /[A-Z]/.test(password),
            lowerCaseValid: /[a-z]/.test(password),
            numberValid: /\d/.test(password),
            specialCharValid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            passwordsMatch: password && confirmPassword && password === confirmPassword,
        });
        setPasswordMatchError(password && confirmPassword && password !== confirmPassword ?
            'Passwords do not match' : '');
    };

    const submitAll = async () => {
        setPasswordChangeSuccess(false);
        setIncorrectPasswordError('');

        const allConditionsMet = Object.values(passwordConditions).every((condition) => condition);
        if (!allConditionsMet) {
            if (!passwordConditions.passwordsMatch) {
                setPasswordMatchError('Passwords do not match');
            }
            return;
        }

        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            setIncorrectPasswordError('Access token not found. Please log in again.');
            return;
        }

        const client = new CognitoIdentityProviderClient({region: awsRegion});
        const command = new ChangePasswordCommand({
            AccessToken: accessToken,
            PreviousPassword: oldPassword,
            ProposedPassword: newPassword,
        });

        try {
            await client.send(command);
            setPasswordChangeSuccess(true);
            swal('Success', 'Your password has been changed successfully!', 'success', {
                buttons: {
                    OK: true,
                },
            }).then(() => history.push('/'));
        } catch (error) {
            console.error('Error changing password:', error);
            setIncorrectPasswordError('Incorrect Password');
        }
    };

    return (
        <div className="table__container">
            <TopTable>
                <TopTable.Title>Change Password
                </TopTable.Title>
            </TopTable>


    <div className="form" style={{display: 'flex', flexDirection: 'column', marginTop: '72px'}}>
                {/* Old Password Field */}
                <label style={{fontSize: '16px', marginBottom: '10.8px'}}>Old Password</label>
                <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <div style={{width: '302px', height: '34px', border: '1px solid #ccc', padding: '5px',
                         borderRadius: '5px'}}>
                        <TextField
                            type={showOldPassword ? 'text' : 'password'}
                            fullWidth
                            onChange={(e) => setOldPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                            edge="end"
                                        >
                                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {incorrectPasswordError && (
                        <div style={{color: 'red', fontSize: '16px', marginLeft: '10px', whiteSpace: 'nowrap'}}>
                            {incorrectPasswordError}
                        </div>
                    )}
                </div>

                {/* New Password Field */}
                <label style={{fontSize: '16px', marginTop: '24.5px', marginBottom: '10.8px'}}>New Password</label>
                <div style={{width: '302px', height: '34px', border: '1px solid #ccc', padding: '5px',
                     borderRadius: '5px'}}>
                    <TextField
                        type={showNewPassword ? 'text' : 'password'}
                        fullWidth
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                        edge="end"
                                    >
                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                {/* Confirm Password Field */}
                <label style={{fontSize: '16px', marginTop: '24.5px', marginBottom: '10.8px'}}>Confirm Password</label>
                <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <div style={{width: '302px', height: '34px', border: '1px solid #ccc', padding: '5px',
                         borderRadius: '5px'}}>
                        <TextField
                            type={showConfirmedPassword ? 'text' : 'password'}
                            fullWidth
                            onChange={(e) => setConfirmedPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowConfirmedPassword(!showConfirmedPassword)}
                                            edge="end"
                                        >
                                            {showConfirmedPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {passwordMatchError && (
                        <div style={{color: 'red', fontSize: '16px', marginLeft: '10px', whiteSpace: 'nowrap'}}>
                            {passwordMatchError}
                        </div>
                    )}
                </div>

                {/* Password Conditions */}
                <div className="password-conditions" style={{marginTop: '20px'}}>
                    <p style={{fontSize: '16px', marginBottom: '10px'}}>Password must contain:</p>
                    <p style={{display: 'flex', alignItems: 'center'}}>
                        {passwordConditions.lengthValid ? (
                            <CheckIcon style={{color: 'green'}} />
                        ) : (
                            <CloseIcon style={{color: 'red'}} />
                        )}
                        &nbsp;At least 8 characters long.
                    </p>
                    <p style={{display: 'flex', alignItems: 'center'}}>
                        {passwordConditions.upperCaseValid ? (
                            <CheckIcon style={{color: 'green'}} />
                        ) : (
                            <CloseIcon style={{color: 'red'}} />
                        )}
                        &nbsp;At least one uppercase letter.
                    </p>
                    <p style={{display: 'flex', alignItems: 'center'}}>
                        {passwordConditions.lowerCaseValid ? (
                            <CheckIcon style={{color: 'green'}} />
                        ) : (
                            <CloseIcon style={{color: 'red'}} />
                        )}
                        &nbsp;At least one lowercase letter.
                    </p>
                    <p style={{display: 'flex', alignItems: 'center'}}>
                        {passwordConditions.numberValid ? (
                            <CheckIcon style={{color: 'green'}} />
                        ) : (
                            <CloseIcon style={{color: 'red'}} />
                        )}
                        &nbsp;At least one number.
                    </p>
                    <p style={{display: 'flex', alignItems: 'center'}}>
                        {passwordConditions.specialCharValid ? (
                            <CheckIcon style={{color: 'green'}} />
                        ) : (
                            <CloseIcon style={{color: 'red'}} />
                        )}
                        &nbsp;At least one special character ($%&...).
                    </p>
                </div>

                {/* Buttons */}
                <div style={{marginTop: '20px', display: 'flex', gap: '18.79px'}}>
                    <Button
                        variant="outlined"
                        color="default"
                        onClick={() => history.push('/')}
                        style={{width: '69.62px', height: '37px'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submitAll}
                        style={{width: '69.62px', height: '37px'}}
                    >
                        Save
                    </Button>
                    {passwordChangeSuccess && (
                        <div style={{color: 'red', fontSize: '16px', marginLeft: '10px', whiteSpace: 'nowrap'}}>
                            Changes saved!
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
