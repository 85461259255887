import Header from '../header';
import Menu from '../menu/Menu';
import PropTypes from 'prop-types';
import log from '../../logging';
import {useEffect} from 'react';
import {fetchCognitoConfig} from '../../configuration';

function Layout(props) {
    log.debug('inside layout page');
    useEffect(() => {
        fetchCognitoConfig();
    }, []);
    return (
        <>
            <Header />
            <div className="container">
                <div className="container__left">
                    <div className="menu">
                        <Menu />
                    </div>
                </div>
                <div className="container__right">{props.children}</div>
            </div>
        </>
    );
}

Layout.propTypes = {
    children: PropTypes.array.isRequired,
};

export default Layout;
