import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import jwtDecode from 'jwt-decode';
import {useHistory} from 'react-router-dom';
import logo from '../../assets/hafele_icon.png';
import './header.scss';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import deleteAllCookiesAndStorage from '../../helpers/frontendCleanup';
import axios from '../../helpers/axios';
import log from '../../logging';
import {logoutUrl} from '../../configuration';
import {redirectUriStem} from '../PrivateRoute';

export const handleRevokeToken = async () => {
    console.log('Revoking token...');
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            console.error('No refresh token found.');
            return;
        }
        const url = `${redirectUriStem}/oauth2/authorization/cognito`;

        const response = await axios.post('api/revoketoken', {refreshToken}, {
            withCredentials: true,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
        });

        if (response.status === 200) {
            log.debug('Token revoked successfully.');
            deleteAllCookiesAndStorage();
            window.location.href = logoutUrl + url;
        } else {
            console.error('Failed to revoke token.');
        }
    } catch (error) {
        console.error('Error revoking token:', error);
    }
};

export default function Header() {
    const [anchorEl, setAnchorEl] = useState(null);

    const history = useHistory();


    const accessToken = localStorage.getItem('accessToken');
    let decodedAccessToken = {};

    if (accessToken) {
        decodedAccessToken = jwtDecode(accessToken);
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    const handleLogOutClick = async () => {
        await handleRevokeToken();
    };

    return (
        <div className="header__container">
            <div className="header__container__left">
                <a href="#/">
                    <img className="header_logo" src={logo} alt="Logo" />
                </a>
            </div>
            <div className="header__container__right">
                <Button id="user__session" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    {decodedAccessToken.username} &#9660;
                </Button>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={() => {
                        handleCloseMenu();
                        history.push('/changepassword');
                    }}>
                        <LockOpenIcon fontSize="small" />
                        <h5 className="button__header">Change Password</h5>
                    </MenuItem>
                    <MenuItem onClick={()=>{
                                                    handleCloseMenu();
                                                    handleLogOutClick();
                                                    }}>
                        <PowerSettingsNewIcon fontSize="small" />
                        <h5 className="button__header">Log Out</h5>
                </MenuItem>

            </Menu>

        </div>
</div>
);
}
