import axios from '../../../helpers/axios';

export const createReport = (payload) => {
    return axios.post(`/FactoryREST/rest/v1/report`, payload, {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    });
};

export const getManufacturer = () => {
    return axios.get(`/FactoryREST/rest/v1/testrun/manufacturers`);
};

export const getDeviceType = () => {
    return axios.get(`/FactoryREST/rest/v1/testspec/devices-type`);
};

export const getModes = () => {
    return axios.get(`/FactoryREST/rest/v1/report/modes`);
};
