import {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from '../../helpers/axios';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

const useStyles = (theme) => ({
    table: {
        minWidth: 650,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    headBackground: {
        backgroundColor: '#E9ECF1',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    tableCell: {cursor: 'pointer'},
    tableCellFailed: {
        backgroundColor: '#F9CCCC',
        cursor: 'pointer',
    },
});

class AlertAdministrationTableContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alerts: [],
        };
    }

    alertTypes = [
        {
            alertType: 'MANDATORY_MEASUREMENT_MISSING',
            description: 'New testrun w/o a mandatory measurement',
        },
        {
            alertType: 'ENVIRONMENTAL_CONDITIONS_HUMIDITY',
            description: 'Improper environment humidity',
        },
        {
            alertType: 'ENVIRONMENTAL_CONDITIONS_TEMPERATURE',
            description: 'Improper environment temperature',
        },
        {
            alertType: 'EXCESSIVE_TESTRUNS',
            description: 'Too many production tests of a serial number',
        },
        {
            alertType: 'MEASUREMENT_RANGE',
            description: 'Too many measurements close to the edge of their range',
        },
        {alertType: 'FIRST_PASS_YIELD', description: 'First Pass Yield too low'},
        {
            alertType: 'OTHER_UPLOAD_ERROR',
            description: 'Could not upload new testrun',
        },
    ];

    componentDidMount = () => {
        const token = localStorage.getItem('accessToken');// token previously
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        axios
            .get('FactoryREST/rest/v1/alert', config)
            .then((response) => {
                const data = response.data;
                // eslint-disable-next-line no-invalid-this
                this.setState({
                    alerts: [...data],
                });
            })
            .catch((error) => {
                console.error('Request Failed:', error.config);
                if (error.response) {
                    console.error('Status:', error.response.status);
                    console.error('Data:', error.response.data);
                    console.error('Headers:', error.response.headers);
                } else {
                    console.error('Error Message:', error.message);
                }
            });
    };

    render() {
        const {alerts} = this.state;
        const {classes} = this.props;

        const alertsList = alerts.length ? (
            alerts.map((alert) => {
                const indexMatch = this.alertTypes.find((currentElement) => {
                    if (currentElement.alertType === alert.type) {
                        return currentElement;
                    }
                    return undefined;
                });
                const alertType = indexMatch !== undefined ? indexMatch.alertType : undefined;
                const description = indexMatch !== undefined ? indexMatch.description : undefined;
                return (
                    <TableRow
                        key={alertType}
                        onClick={() =>
                            this.props.history.push({
                                pathname: '/alertadministration/' + alertType,
                                alert: alert,
                            })
                        }
                    >
                        <TableCell className={classes.tableCell}>{alertType}</TableCell>
                        <TableCell className={classes.tableCell}>{description}</TableCell>
                    </TableRow>
                );
            })
        ) : (
            <TableRow>
                <TableCell className={classes.tableCell}>No alerts</TableCell>
                <TableCell className={classes.tableCell}>No description</TableCell>
            </TableRow>
        );
        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow className={classes.headBackground}>
                            <TableCell className={classes.tableCell}>Alerts</TableCell>
                            <TableCell className={classes.tableCell}>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{alertsList}</TableBody>
                </Table>
            </TableContainer>
        );
    }
}
AlertAdministrationTableContent.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(withStyles(useStyles)(AlertAdministrationTableContent));
