import {searchConstants} from '../constans/constans';

export function setDevType(payload) {
    return {
        type: searchConstants.SET_DEVTYPE,
        payload,
    };
}

export function setSpecVer(payload) {
    return {
        type: searchConstants.SET_SPECVER,
        payload,
    };
}

export function setManufacturer(payload) {
    return {
        type: searchConstants.MANUFACTURER,
        payload,
    };
}

export function setTestTime(payload) {
    return {
        type: searchConstants.TESTTIME,
        payload,
    };
}

export function setStartDateVL(payload) {
    return {
        type: searchConstants.START_DATE,
        payload,
    };
}

export function setEndDateVL(payload) {
    return {
        type: searchConstants.END_DATE,
        payload,
    };
}

export function setDut(payload) {
    return {
        type: searchConstants.DUT,
        payload,
    };
}

export function setSucces(payload) {
    return {
        type: searchConstants.SUCCESS,
        payload,
    };
}

export function setMode(payload) {
    return {
        type: searchConstants.MODE,
        payload,
    };
}

export function setCreatedBy(payload) {
    return {
        type: searchConstants.CREATEDBY,
        payload,
    };
}

export function setState(payload) {
    return {
        type: searchConstants.STATE,
        payload,
    };
}

export function setApprovedBy(payload) {
    return {
        type: searchConstants.APPROVEDBY,
        payload,
    };
}

export function setUserName(payload) {
    return {
        type: searchConstants.USER_NAME,
        payload,
    };
}

export function setRoles(payload) {
    return {
        type: searchConstants.ROLE,
        payload,
    };
}

export function setPageTestSpecs(payload) {
    return {
        type: searchConstants.PAGE_TEST_SPECS,
        payload,
    };
}

export function setPageUsers(payload) {
    return {
        type: searchConstants.PAGE_USERS,
        payload,
    };
}

export function setPageTestRuns(payload) {
    return {
        type: searchConstants.PAGE_TEST_RUNS,
        payload,
    };
}

export function setSizeTestRuns(payload) {
    return {
        type: searchConstants.SIZE_TEST_RUNS,
        payload,
    };
}

export function searchLogout() {
    return {
        type: searchConstants.SEARCH_LOGOUT,
    };
}
