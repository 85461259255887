import React, {useEffect} from 'react'; // {useState}
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import Layout from '../layout';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
// import log from '../../logging';

export let redirectUriStem = ''; // Declare redirectUri here for export


const PrivateRoute = ({component: Component, ...rest}) => {
    console.log('inside PrivateRoute component');
    // const [localRedirectUri] = useState(''); // Store the redirect URI

    let isTokenValid = false;
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

    const isTokenPresent = !!localStorage.getItem('accessToken');
    console.log('inside line 2 of PrivateRoute component');
    if (isTokenPresent) {
        console.log('inside if isTokenPresent in PrivateRoute component');
        const accessToken = localStorage.getItem('accessToken');
        const decodedToken = jwtDecode(accessToken);
        isTokenValid = currentTime < decodedToken.exp;
    }


    useEffect(() => {
        // Fetch redirect URI from the backend
        console.log('inside useEffect of PrivateRoute component');
        const fetchRedirectUri = async () => {
            try {
                const response = await axios.get('/api/config/redirect-uri');
                console.log('response.data.redirectUrl', response.data.redirectUrl);
                console.log('response.data.redirectStem', response.data.redirectStem);
                redirectUriStem = response.data.redirectStem;
            } catch (error) {
                console.error('Error fetching redirect URI:', error);
            }
        };
        fetchRedirectUri();
    }, []); // Empty dependency array means this effect runs only once on mount

    return (
        <Route
            {...rest}
            render={(props) => {
                console.log('Rendering PrivateRoute component');
                console.log('isTokenValid:', isTokenValid);
                if (isTokenValid) {
                    console.log('Token is valid, rendering the component');
                    return (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    );
                } else {
                    console.log('Token is invalid or expired. Redirecting to:',
                        `${redirectUriStem}/oauth2/authorization/cognito`);
                    // Clear localStorage before redirecting
                    localStorage.clear();
                    window.location.href = `${redirectUriStem}/oauth2/authorization/cognito`; // 'http://localhost:5000/oauth2/authorization/cognito';
                }
            }
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired, // component is required and must be a valid React component
    rest: PropTypes.object, // the remaining props
};

export default PrivateRoute;
