import TextField from '@material-ui/core/TextField';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setTestSpecversion} from '../../../redux/actions/reportAction';

export const TestSpecVersion = () => {
    const [testspecversionst, setTestspecversionst] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTestSpecversion(testspecversionst));
    }, [dispatch, testspecversionst]);

    return (
        <TextField
            className="tableRowCellSV"
            id="outlined-basic"
            placeholder="All"
            size="small"
            value={testspecversionst}
            onChange={(e) => setTestspecversionst(e.target.value)}
        />
    );
};
