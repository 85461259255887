// export const authConstants = {
//     LOGIN_REQUEST: 'LOGIN_REQUEST',
//     LOGIN_FAILURE: 'LOGIN_FAILURE',
//     LOGIN_SUCCESS: 'LOGIN_SUCCESS',
//     LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
//     IS_REFRESHED: 'IS_REFRESHED',
// };

export const searchConstants = {
    SET_DEVTYPE: 'SET_DEVTYPE',
    SET_SPECVER: 'SET_SPECVER',
    MANUFACTURER: 'MANUFACTURER',
    START_DATE: 'START_DATE',
    END_DATE: 'END_DATE',
    DUT: 'DUT',
    SUCCESS: 'SUCCESS',
    MODE: 'MODE',
    CREATEDBY: 'CREATEDBY',
    APPROVEDBY: 'APPROVEDBY',
    USER_NAME: 'USER_NAME',
    ROLE: 'ROLE',
    STATE: 'STATE',
    SEARCH_LOGOUT: 'SEARCH_LOGOUT',
    PAGE_TEST_SPECS: 'PAGE_TEST_SPECS',
    PAGE_TEST_RUNS: 'PAGE_TEST_RUNS',
    SIZE_TEST_RUNS: 'SIZE_TEST_RUNS',
    PAGE_USERS: 'PAGE_USERS',
};

export const createTSConstants = {
    CREATE_DEVTYPE: 'CREATE_DEVTYPE',
    CREATE_VERSION: 'CREATE_VERSTION',
    CREATE_MEAS: 'CREATE_MEAS',
    DELETE_ALL: 'DELETE_ALL',
};

export const editTSConstants = {
    EDIT_DEVTYPE: 'EDIT_DEVTYPE',
    EDIT_VERSION: 'EDIT_VERSTION',
    EDIT_MEAS: 'EDIT_MEAS',
};

// export const newUserConstants = {
//     USERNAME: 'USERNAME',
//     EMAIL: 'EMAIL',
//     RESTRICT: 'RESTRICT',
//     ROLES: 'ROLES',
//     ALERTS: 'ALERTS',
//     RESET: 'RESET',
// };

export const tabConstants = {
    TOGGLE: 'TOGGLE',
};

// export const refreshConstants = {
//     REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
//     REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
//     REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
// };

export const reportConstants = {
    SET_TESTER: 'SET_TESTER',
    SET_MANUFACTURER: 'SET_MANUFACTURER',
    SET_DEVICE_TYPE: 'SET_DEVICE_TYPE',
    SET_MODE: 'SET_MODE',
    SET_INTERVAL: 'SET_INTERVAL',
    SET_START_DATE: 'SET_START_DATE',
    SET_END_DATE: 'SET_END_DATE',
    SET_USER: 'SET_USER',
    SET_TESTSPEC_VERSION: 'SET_TESTSPEC_VERSION',
    SET_DEVICE_ID: 'SET_DEVICE_ID',
    SET_INTERVAL_EINZ: 'SET_INTERVAL_EINZ',
    SET_MEASUREMENT: 'SET_MEASUREMENT',
    SET_SECOND_MEASUREMENT: 'SET_SECOND_MEASUREMENT',
    SET_THIRD_MEASUREMENT: 'SET_THIRD_MEASUREMENT',
    SET_FOURTH_MEASUREMENT: 'SET_FOURTH_MEASUREMENT',
    SET_FIFTH_MEASUREMENT: 'SET_FIFTH_MEASUREMENT',
    SET_TEST_SUCCESS: 'SET_TEST_SUCCESS',
};
