import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './styles/styles.scss';
import PropTypes from 'prop-types';

export default function TestRunsItemDetails({
    providedId,
    deviceTypeItem,
    modusItem,
    deviceIdItem,
    timeStampItem,
    durationItem,
    accordingToItem,
    testerItem,
    testerSoftwareVersion,
    operatorItem,
    manufacturerId,
    orderNumber,
    timeZoneItem,
    allMeasurements,
    components,
}) {
    return (
        <div className="item__details__container">
            <div className="item__detail__tables">
                <div className="item__detail__table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Device type:</div>
                                    <h4>{deviceTypeItem}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Modus:</div>
                                    <h4>{modusItem}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Device id:</div>
                                    <h4>{deviceIdItem}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5, flexDirection: 'column'}}>
                                    <div>Provided id:</div>
                                    <h4>{providedId}</h4>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
                <div className="item__detail__table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Timestamp:</div>
                                    <h4>{timeStampItem}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Timezone:</div>
                                    <h4>{timeZoneItem}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Duration:</div>
                                    <h4>{durationItem + 'ms'}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>According to spec:</div>
                                    <h4>{accordingToItem}</h4>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
                <div className="item__detail__table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Tester:</div>
                                    <h4>{testerItem}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>SW version:</div>
                                    <h4>{testerSoftwareVersion}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Operator:</div>
                                    <h4>{operatorItem}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Manufacturer id:</div>
                                    <h4>{manufacturerId}</h4>
                                </TableCell>
                                <TableCell className="tableCell" style={{padding: 5}}>
                                    <div>Order number:</div>
                                    <h4>{orderNumber}</h4>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
            </div>
            <div>
                <Table className="item__device__container">
                    <TableHead>
                        <TableRow className="item__device__tables">
                            <TableCell className="item__device__cell__head" style={{padding: 5}}>
                                <h4>Device type</h4>
                            </TableCell>
                            <TableCell className="item__device__cell__head" style={{padding: 5}}>
                                <h4>Device id</h4>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {components.length > 0 ? (
                            components.map((component) => {
                                return (
                                    <TableRow key={component.dut_id}>
                                        <TableCell className="item__device__cell__body" style={{padding: 5}}>
                                            <div>{component.type}</div>
                                        </TableCell>
                                        <TableCell className="item__device__cell__body" style={{padding: 5}}>
                                            <div>{String(component.dut_id)}</div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell className="item__device__cell__body">
                                    <div></div>
                                </TableCell>
                                <TableCell className="item__device__cell__body">
                                    <div></div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="item__details__container">
                <Table>
                    <TableHead>
                        <TableRow className="item__device__tables">
                            <TableCell className="item__details__cell" style={{padding: 5}}>
                                <h4>Name</h4>
                            </TableCell>
                            <TableCell className="item__details__cell" style={{padding: 5}}>
                                <h4>Valid value</h4>
                            </TableCell>
                            <TableCell className="item__details__cell" style={{padding: 5}}>
                                <h4>Values</h4>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allMeasurements
                            .sort(function (a, b) {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (a.name > b.name) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((value) => {
                                return (
                                    <TableRow className={value.valid ? '' : 'tableRowFalse'} key={value.name}>
                                        <TableCell className="item__details__cell__body">
                                            <div>{value.name}</div>
                                        </TableCell>
                                        <TableCell className="item__details__cell__body">
                                            <div>{value.valid_values}</div>
                                        </TableCell>
                                        <TableCell className="item__details__cell__body">
                                            <div>{value.value}</div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

TestRunsItemDetails.propTypes = {
    providedId: PropTypes.any,
    deviceTypeItem: PropTypes.string.isRequired,
    modusItem: PropTypes.string.isRequired,
    deviceIdItem: PropTypes.string.isRequired,
    timeStampItem: PropTypes.string.isRequired,
    durationItem: PropTypes.number.isRequired,
    accordingToItem: PropTypes.string.isRequired,
    testerItem: PropTypes.string.isRequired,
    testerSoftwareVersion: PropTypes.string.isRequired,
    operatorItem: PropTypes.string.isRequired,
    manufacturerId: PropTypes.string.isRequired,
    orderNumber: PropTypes.any,
    timeZoneItem: PropTypes.string.isRequired,
    allMeasurements: PropTypes.array.isRequired,
    components: PropTypes.array.isRequired,
};
