import axios from '../../../helpers/axios';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {setManufacturer} from '../../../redux/actions/reportAction';

export const Manufacturer = () => {
    const getManufacturer = () => {
        return axios.get(`/FactoryREST/rest/v1/testrun/manufacturers`).then((res) =>
            setManufcaturerArr(
                res.data.sort(function (a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                }),
            ),
        );
    };

    const [manufcaturerArr, setManufcaturerArr] = useState([]);
    const [manufacturerst, setManufacturerst] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        getManufacturer();
    }, []);

    useEffect(() => {
        dispatch(setManufacturer(manufacturerst));
    }, [dispatch, manufacturerst]);

    return (
        <FormControl size="small" className="report__input">
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={manufacturerst}
                onChange={(e) => setManufacturerst(e.target.value)}
                size="small"
                name="all"
                displayEmpty
            >
                <MenuItem size="small" value={''}>
                    All
                </MenuItem>
                {manufcaturerArr.length !== 0
                    ? manufcaturerArr.map((item, i) => {
                          return (
                              <MenuItem key={i} value={`${item}`}>
                                  {item}
                              </MenuItem>
                          );
                      })
                    : ''}
            </Select>
        </FormControl>
    );
};
