import {useEffect, useState} from 'react';
import axios from '../../helpers/axios';
import TestSpecsContentById from './TestSpecsContentById';
import PropTypes from 'prop-types';
import log from '../../logging';

export default function TestSpecById({match}) {
    useEffect(() => {
        log.warn('match is: {}', match);
        getPostById(match.params.id);
    }, [match.params.id]);

    const [theTestSpec, setTheTestSpec] = useState(null);
    const [selectedSpecId] = useState(match.params.id);

    const getPostById = (id) => {
        axios
            .get(`/FactoryREST/rest/v1/testspec/${id}`)
            .then((res) => {
                setTheTestSpec(res.data);
                log.warn('theTestSpec is: {}', res.data);
            })
            .catch((err) => console.log('err:', err));
    };

    if (theTestSpec !== null) {
        return (
            <div className="table__container">
                <TestSpecsContentById id={selectedSpecId} theTestSpec={theTestSpec} />
            </div>
        );
    } else return null;
}

TestSpecById.propTypes = {
    match: PropTypes.object.isRequired,
};
