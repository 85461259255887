import EditUser from './EditUser';
import PropTypes from 'prop-types';

function UserById({match}) {
    return <EditUser usernameMatch={match.params.username} />;
}

UserById.propTypes = {
    match: PropTypes.object.isRequired,
};

export default UserById;
