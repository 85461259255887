import {editTSConstants} from '../constans/constans';

const initialState = {
    deviceType: '',
    version: '',
    meas: [],
};

const editTSReducer = (state = initialState, action) => {
    switch (action.type) {
        case editTSConstants.EDIT_DEVTYPE:
            return {
                ...state,
                deviceType: action.payload,
            };
        case editTSConstants.EDIT_VERSION:
            return {
                ...state,
                version: action.payload,
            };
        case editTSConstants.EDIT_MEAS:
            return {
                ...state,
                meas: action.payload,
            };
        default:
            return state;
    }
};

export default editTSReducer;
