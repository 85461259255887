import {tabConstants} from '../constans/constans';

const initialState = {
    tabType: '',
};

const tabReducer = (state = initialState, action) => {
    switch (action.type) {
        case tabConstants.TOGGLE:
            return {
                tabType: action.payload,
            };
        default:
            return state;
    }
};

export default tabReducer;
