import {Component} from 'react';
import PropTypes from 'prop-types';
import TopTable from '../../components/toptable';
import Card from '@material-ui/core/Card';
import {Button, CardContent, GridList, GridListTile, TextField} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBack from '@material-ui/icons/ArrowBack';
import EmailEditor from './email/emailEditor';
import axios from '../../helpers/axios';

// regex expressions
const regexForFloat = /^[-]?\d+(\.\d+)?$/;
const regexForDecimal = /^\d+$/;

class Alert extends Component {
    constructor(props) {
        super(props);

        const alert = props.location.alert;

        this.state = {
            type: alert.type,
            emailTemplate: alert.email_template,
            lowerLimit: alert.lower_limit,
            upperLimit: alert.upper_limit,
            rateLimit: alert.rate_limit,
            rateLimited: alert.rate_limited,
            lastSent: alert.last_sent,
            rateLimitError: false,
            lowerLimitError: false,
            upperLimitError: false,
        };

        this.onEmailTemplateChange = this.onEmailTemplateChange.bind(this);
    }

    handleClickEventOnReturnButton = () => {
        props.history.push('/alertadministration');
    };

    handleClickEventOnSaveButton = () => {
        // check if input values are correct
        const {lowerLimitError, upperLimitError, rateLimitError} = state;
        if ((lowerLimitError || upperLimitError || rateLimitError) === true) {
            // notify user about wrong input ?
            return;
        }

        const token = localStorage.getItem('accessToken');// token previously
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };

        const data = {
            emailTemplate: state.emailTemplate,
            lowerLimit: state.lowerLimit,
            upperLimit: state.upperLimit,
            rateLimit: state.rateLimit,
        };

        axios
            .put('FactoryREST/rest/v1/alert/' + state.type, data, config)
            .then((response) => {
                // do something from response
                // like notifying user about successful save
                console.log(response);
            })
            .catch((error) => {
                console.error('Put Request Failed:', error.config);
                if (error.response) {
                    console.error('Status:', error.response.status);
                    console.error('Data:', error.response.data);
                    console.error('Headers:', error.response.headers);
                } else {
                    console.error('Error Message:', error.message);
                }
            });
    };

    handleChangeOfValue(event) {
        let match = false;
        const error = event.target.id + 'Error';

        switch (event.target.id) {
            case 'rateLimit':
                if (!(event.target.value === '' || regexForDecimal.test(event.target.value))) {
                    match = true;
                }
                break;
            case 'lowerLimit': /** fall through */
            case 'upperLimit':
                if (!(event.target.value === '' || regexForFloat.test(event.target.value))) {
                    match = true;
                }
                break;
            default:
            // do nothing
        }

        // change the state value in regard to the input value
        this.setState({
            [event.target.id]: Number.parseFloat(event.target.value),
            [error]: match,
        });
    }

    onEmailTemplateChange = (emailTemplate) => {
        // eslint-disable-next-line no-invalid-this
        this.setState({
            emailTemplate: emailTemplate,
        });
    };

    render() {
        return (
            <div>
                <div className="table__container">
                    <TopTable>
                        <TopTable.Title>Alerts</TopTable.Title>
                        <div className="table__container__right"></div>
                    </TopTable>
                    <Card>
                        <CardContent>
                            <GridList cellHeight="auto" cols={2}>
                                <GridListTile cols={1}>
                                    <h3>{this.state.type}</h3>
                                </GridListTile>
                                <GridListTile cols={1}></GridListTile>
                                <GridListTile hidden="true" cols={2}>
                                    <TextField
                                        label="Last sent"
                                        id="lastSent"
                                        size="small"
                                        defaultValue={this.state.lastSent}
                                    />
                                </GridListTile>
                                <GridListTile cols={1}>
                                    <TextField
                                        label={
                                            'Lower limit' +
                                            (this.state.lowerLimitError ? ': The input value is not a number' : '')
                                        }
                                        id="lowerLimit"
                                        error={this.state.lowerLimitError}
                                        size="small"
                                        defaultValue={this.state.lowerLimit}
                                        onChange={(event) => {
                                            this.handleChangeOfValue(event);
                                        }}
                                    />
                                    <TextField
                                        label={
                                            'Upper limit' +
                                            (this.state.upperLimitError ? ': The input value is not a number' : '')
                                        }
                                        id="upperLimit"
                                        error={this.state.upperLimitError}
                                        size="small"
                                        defaultValue={this.state.upperLimit}
                                        onChange={(event) => {
                                            this.handleChangeOfValue(event);
                                        }}
                                    />
                                </GridListTile>
                                <GridListTile cols={1}>
                                    <TextField
                                        label={
                                            'Rate limit' +
                                            (this.state.rateLimitError ? ': The input value is not a number' : '')
                                        }
                                        id="rateLimit"
                                        error={this.state.rateLimitError}
                                        size="small"
                                        defaultValue={this.state.rateLimit}
                                        onChange={(event) => {
                                            this.handleChangeOfValue(event);
                                        }}
                                    />
                                    <TextField
                                        hidden={true}
                                        disabled={true}
                                        label="Rate limited"
                                        id="rateLimited"
                                        size="small"
                                        defaultValue={this.state.rateLimited}
                                    />
                                </GridListTile>
                                <GridListTile cols={2}>
                                    <EmailEditor
                                        id="emailTemplate"
                                        emailTemplate={this.state.emailTemplate}
                                        onEmailTemplateChange={this.onEmailTemplateChange}
                                    />
                                </GridListTile>
                                <GridListTile cols={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        startIcon={<ArrowBack />}
                                        onClick={this.handleClickEventOnReturnButton}
                                    >
                                        Back to Alert Overview
                                    </Button>
                                </GridListTile>
                                <GridListTile cols={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        startIcon={<SaveIcon />}
                                        onClick={this.handleClickEventOnSaveButton}
                                    >
                                        Save
                                    </Button>
                                </GridListTile>
                            </GridList>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
}
Alert.propTypes = {
    location: PropTypes.object.isRequired,
};

export default Alert;
