import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import {useEffect, useRef, useState} from 'react';
import {setRoles, setUserName} from '../../redux/actions/searchAction';
import {useDispatch, useSelector} from 'react-redux';
import './styles/styles.scss';
import log from '../../logging';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    headBackground: {
        backgroundColor: '#E9ECF1',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    tableCell: {cursor: 'pointer'},
    tableCell2: {
        textAlign: 'center',
    },
    tableCellFailed: {
        backgroundColor: '#F9CCCC',
        cursor: 'pointer',
    },
}));

const UsersTableContent = ({users}) => {
    log.debug('inside UsersTableContent');
    log.debug('from inside the UsersTableContent, users: ', users);

    const dispatch = useDispatch();

    const usernameStore = useSelector((state) => state.search.usernameEP);
    const roleStore = useSelector((state) => state.search.roleEP);

    const classes = useStyles();
    const history = useHistory();

    const [usernameST, setUsernameST] = useState(usernameStore || '');
    const [rolesST, setRolesST] = useState(roleStore || '');

    const getUserRoles = (roles) => {
        return roles?.join(',') || '';
    };

    // Ref to store the timeout ID
    const typingTimeoutRef = useRef(null);
    const rolesInputRef = useRef(null); // Ref for the roles search input

    // Handle changes in the roles search field using setTimeout for debouncing
    const handleRolesChange = async (e) => {
        const value = e.target.value;
        setRolesST(value); // Update local state immediately

        // Clear the previous timeout to prevent multiple API calls
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        // Set a new timeout to delay the API call
        typingTimeoutRef.current = setTimeout(() => {
            dispatch(setRoles(value)); // Trigger the Redux state update after the user stops typing

            log.debug(`Role search triggered with value: ${value}`);
            history.replace('/users2');
        }, 500);
    };

    // Only update Redux store when user types in the search inputs
    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setUsernameST(value);
        dispatch(setUserName(value));
    };


    useEffect(() => {
        dispatch(setUserName(usernameST));
        dispatch(setRoles(rolesST));
    }, [dispatch, usernameST, rolesST]);


    // useEffect to focus the input and set the cursor position after reload
    useEffect(() => {
        if (rolesInputRef.current) {
            rolesInputRef.current.focus(); // Set focus to the input
            // Move the cursor to the end of the input field
            rolesInputRef.current.setSelectionRange(rolesST.length, rolesST.length);
        }
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow className={classes.headBackground}>
                        <TableCell className={classes.tableCells}>User</TableCell>
                        <TableCell className={classes.tableCells}>Role</TableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow className="tableRow">
                        <TableCell className="tableRowCell">
                            <TextField
                                className="tableRowCellSV"
                                placeholder="Search"
                                size="small"
                                value={usernameST}
                                onChange={handleUsernameChange}
                            />
                        </TableCell>
                        <TableCell className="tableRowCell">
                            <TextField
                                className="tableRowCellSV"
                                placeholder="Search"
                                size="small"
                                onChange={handleRolesChange}
                                value={rolesST}
                                inputRef={rolesInputRef}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => {
                        // console.log('user='+user);
                        log.debug('user.username='+user.username);
                        log.debug('user.roles='+user.roles);

                        return (
                            <TableRow
                                key={user.username}
                                className={classes.tableCell}
                                onClick={() => history.push(`users/edit/${user.username}`)}
                            >
                                <TableCell className={classes.tableCell}>{user.username}</TableCell>
                                <TableCell className={classes.tableCell} style={{color: 'black'} }>
                                    {getUserRoles(user.roles)}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


UsersTableContent.propTypes = {
users: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired),
})).isRequired,
};

export default UsersTableContent;
