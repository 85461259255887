import {useState} from 'react';
import {useSelector} from 'react-redux';
import swal from 'sweetalert';
import ReportRow from '../../../components/reportContainer';
import ReportExportButtons from '../../../components/reportExportButtons';
import DeviceType from '../../../components/reportsParameters/deviceType';
import {EndDate} from '../../../components/reportsParameters/endDate';
import {Interval} from '../../../components/reportsParameters/interval';
import {Manufacturer} from '../../../components/reportsParameters/manufacturer';
import {Mode} from '../../../components/reportsParameters/mode';
import {StartDate} from '../../../components/reportsParameters/startDate';
import {Tester} from '../../../components/reportsParameters/tester';
import {TestSpecVersion} from '../../../components/reportsParameters/testspecVersion';
import {Users} from '../../../components/reportsParameters/user';
import TopTable from '../../../components/toptable';
import {Report} from '../services';

function CreateFirstPassYield() {
    const reportName = 'FirstPassYield';

    const [isSpinner, setIsSpinner] = useState(false);
    const report = useSelector((state) => state.report);

    const handleSubmit = (value) => {
        setIsSpinner(true);
        const payload = {
            format: value,
            file_name: reportName,
            parameters: {
                timeintervallstr: report.interval,
                startdate_input: report.startdate,
                enddate_input: report.enddate,
                manufactureropt: report.manufacturer,
                testeropt: report.tester,
                devicetypeopt: report.devicetype,
                specversionopt: report.testspecversion,
                operatoropt: report.user,
                testmodeopt: report.mode,
            },
        };
        Report.createReport(payload)
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                const fileName =
                    `${reportName}_` + new Date().toLocaleDateString() + '-' + new Date().toLocaleTimeString();
                setIsSpinner(false);
                link.href = url;
                link.setAttribute('download', `${fileName}.${value}`); // or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setIsSpinner(false);
                swal(`Something went wrong`, `Please try again`, 'warning', {
                    buttons: {
                        OK: true,
                    },
                });
            });
    };

    return (
        <>
            <div className="container__report">
                <TopTable>
                    <TopTable.Title>First Pass Yield</TopTable.Title>
                    <TopTable.Body></TopTable.Body>
                </TopTable>
                <div className="container__parameters">
                    <div className="container__parameter">
                        <h3>Mandatory parameters</h3>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>Interval</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <Interval />
                            </ReportRow.Right>
                        </ReportRow>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>Start date:</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <StartDate />
                            </ReportRow.Right>
                        </ReportRow>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>End date:</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <EndDate />
                            </ReportRow.Right>
                        </ReportRow>
                        <div className="row__button">
                            <ReportExportButtons handleSave={handleSubmit} isLoading={isSpinner} />
                        </div>
                    </div>
                    <div className="container__parameter">
                        <h3>Optional parameters</h3>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>Manufacturer:</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <Manufacturer />
                            </ReportRow.Right>
                        </ReportRow>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>Device type:</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <DeviceType optional={true} />
                            </ReportRow.Right>
                        </ReportRow>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>Tester:</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <Tester />
                            </ReportRow.Right>
                        </ReportRow>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>Testspec version:</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <TestSpecVersion />
                            </ReportRow.Right>
                        </ReportRow>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>User/Operator:</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <Users />
                            </ReportRow.Right>
                        </ReportRow>
                        <ReportRow>
                            <ReportRow.Left>
                                <p>Mode:</p>
                            </ReportRow.Left>
                            <ReportRow.Right>
                                <Mode />
                            </ReportRow.Right>
                        </ReportRow>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateFirstPassYield;
