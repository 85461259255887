import {Select} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
        maxWidth: 600,
        minWidth: 140,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: '12px',
    },
    chip: {
        margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 225,
        },
    },
};

const UserRolesTable = ({roles, boxVal, handleBoxClick, handleBoxVal}) => {
    const classes = useStyles();
    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel>Roles</InputLabel>
                <Select
                    multiple
                    value={boxVal.filter(Boolean)}
                    input={<Input />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                    rows={boxVal.filter(Boolean).length}
                >
                    {roles.map((role, index) => (
                        <MenuItem key={role} value={role}>
                            <Checkbox
                                checked={boxVal[index]}
                                value={role}
                                onClick={(e) => {
                                    handleBoxClick(role, index);
                                    handleBoxVal(e.target.checked, index);
                                }}
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                            <ListItemText primary={role} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

UserRolesTable.propTypes = {
    roles: PropTypes.object.isRequired,
    boxVal: PropTypes.object.isRequired,
    handleBoxClick: PropTypes.object.isRequired,
    handleBoxVal: PropTypes.object.isRequired,
};

export default UserRolesTable;
